import * as React from "react"
import { useEffect, useRef, Dispatch } from "react"

interface DropdownMenuProps<T> {
  options: Array<T>
  activeOption: number
  setActiveOption: Dispatch<number>
  setAll: Dispatch<T | T[]>
  renderOption: (option: T) => JSX.Element
  isOptionSelected: (option: T) => boolean
  isOptionDisabled?: (option: T) => boolean
}

export const DropdownMenu = ({
  options,
  activeOption,
  setActiveOption,
  setAll,
  renderOption,
  isOptionSelected,
  isOptionDisabled,
}: DropdownMenuProps<any | string>): JSX.Element => {
  /**
  Options menu for a multiselect dropdown
  * options: an Array of options (string)
  * activeOption: current option selected/highlighted. The menu closes if this value is set to null.
  * setActiveOption: sets the selected/highlighted option
  * setAll: sets the dropdown value and resets the search input
  * renderOption: function to render an option
  * isOptionSelected: function to assess whether an option is selected, given value
  * isOptionDisabled: function to assess whether an option is selected, given value
  */

  // Reference to the main container to track the scroll position and to check whether the menu is open
  const menuRef = useRef<HTMLDivElement>()

  isOptionDisabled = isOptionDisabled || (o => false)

  // Keyboard inputs management
  useEffect(() => {
    if (activeOption !== null) {
      // Get the number of options to circle back to the beginning when reaching one end
      const n_options = options.length

      const handleKeyEvents = e => {
        // Only handle events if the menu is open -> height is > 0
        if (menuRef.current?.offsetHeight > 0) {
          if (e.key === "Escape") {
            // Close the menu on Escape by setting the activeOption to null
            setActiveOption(null)
          } else if (e.key === "ArrowDown") {
            // Set the activeOption to the next option on ArrowDown
            const newActiveOption = (activeOption + 1) % n_options
            setActiveOption(newActiveOption)
            const activeEl = menuRef.current.children[newActiveOption] as HTMLDivElement
            menuRef.current.scrollTop = activeEl.offsetTop
          } else if (e.key === "ArrowUp") {
            // Set the activeOption to the previous option on ArrowDown
            const newActiveOption = (((activeOption - 1) % n_options) + n_options) % n_options
            const activeEl = menuRef.current.children[newActiveOption] as HTMLDivElement
            menuRef.current.scrollTop = activeEl.offsetTop
            setActiveOption(newActiveOption)
          } else if (e.key === "Enter") {
            // Add the activeOption to the multiselect value and reset the search input
            setAll(options[activeOption])
          }
        }
      }

      window.addEventListener("keydown", handleKeyEvents)
      return () => {
        window.removeEventListener("keydown", handleKeyEvents)
      }
    }
  }, [activeOption, setActiveOption, options, setAll, menuRef])

  // Close the menu if there areno options or the activeOption is null
  if (!options || !options.length || activeOption == null) {
    return null
  }

  return (
    <div
      className={`
      absolute overflow-auto w-full max-h-60 py-1 mt-1 text-base z-20
      bg-zinc-200 dark:bg-zinc-600 rounded-sm shadow-lg
      hidden hover:block peer-focus:block
      bottom-0 translate-y-full thin-scrollbar
    `}
      ref={menuRef}
    >
      {/* Map the options to div elements */}
      {options.map((option, i) => {
        // Add the option to the multiselect value when option is clicked
        return (
          <div
            key={i}
            className={`
          pl-10 pr-4 py-1 relative w-full text-left hover:bg-secondary/25 dark:hover:bg-secondary-400/25
          ${i === activeOption ? "bg-secondary/25 dark:bg-secondary-400/25" : ""}
          ${isOptionDisabled(option) ? "text-zinc-500 dark:text-zinc-400" : ""}
        `}
            onClick={async e => {
              e.preventDefault()
              setAll(option)
            }}
          >
            {renderOption(option)}
            {/* Show a tick if this option is part of the multiselect value */}
            {isOptionSelected(option) && (
              <span className="text-secondary absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden={true}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}
