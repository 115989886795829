import * as React from "react"
import { Fragment, Dispatch } from "react"
import { Transition, Dialog } from "@headlessui/react"

interface BasicModalProps {
  show: boolean
  setShow: Dispatch<boolean>
  onClose?: (value: boolean) => void
  children: (JSX.Element | string) | (JSX.Element | string)[]
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl"
}

export const BasicModal = ({
  show,
  setShow,
  onClose,
  children,
  size,
}: BasicModalProps): JSX.Element => {
  /*
  A modal with default settings
  * show defines whether the modal is shown
  * setShow shows/hides the modal
  * onClose defines what happens when clicking outside the modal / pressing Escape
  * children is the modal's content
  * size is the horizontal size (from sm to 5xl)
  */

  // Get the size taliwind class
  size = size || "2xl"
  const max_size = {
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg",
    xl: "max-w-xl",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "4xl": "max-w-4xl",
    "5xl": "max-w-5xl",
  }[size]

  // Function to close the modal
  const closeModal = () => setShow(false)

  return (
    <Transition appear show={show} as={Fragment}>
      {/* Fixed position Dialog on the whole screen */}
      <Dialog
        onClose={onClose || closeModal}
        as="div"
        className="fixed inset-0 z-[100000] overflow-y-auto text-zinc-800 dark:text-zinc-200"
      >
        <div className="min-h-screen px-1 sm:px-2 md:px-4 text-center">
          {/* Fade-in overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-zinc-800/75 z-0" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="relative inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          {/* Transitioning in contents*/}
          <Transition.Child
            className={`
              relative inline-block w-full ${max_size} p-2 sm:p-4 my-2 text-left align-middle transition-all
              bg-white dark:bg-zinc-800 shadow-2xl rounded-md border-zinc-300 dark:border-zinc-700 border
            `}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-80 -translate-y-8"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-80"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
