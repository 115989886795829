import app from "gatsby-plugin-firebase-v9.0"
import { getAnalytics } from "firebase/analytics"
import * as firebase from "firebase/app"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage"

export const initFirebase = () => {
  let analytics = null
  try {
    // if (location?.hostname === "localhost") {
    //   const db = getFirestore(app)
    //   connectFirestoreEmulator(db, "localhost", 8080)
    //   const auth = getAuth(app)
    //   connectAuthEmulator(auth, "http://localhost:9099")
    //   const functions = getFunctions(app, "australia-southeast1")
    //   connectFunctionsEmulator(functions, "localhost", 5001)
    //   const storage = getStorage(app)
    //   connectStorageEmulator(storage, "localhost", 9199)
    // }
    analytics = getAnalytics(app)
  } catch (e) {
    console.log(e)
  }
  return analytics
}
