import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  query,
  where,
  onSnapshot,
  getDocs,
  setDoc,
} from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { StripePrice, StripeProduct, UserSubscription } from "./model"
import { Stripe } from "@stripe/stripe-js"

const functionLocation = "australia-southeast1"
const codesRef = () => collection(getFirestore(app), "codes")
const USER_CODES = "userCodes"

export const getPortalUrl = async (organisation: string): Promise<string> => {
  const functionRef = httpsCallable(
    getFunctions(app, functionLocation),
    "ext-firestore-stripe-subscriptions-createPortalLink",
  )
  const { data } = await functionRef({
    returnUrl: `${window.location.origin}/app/organisations/${organisation}/settings`,
  })
  return data.url
}

export const getReferralCode = async (uid: string): Promise<string> => {
  const userCodes = (await getDoc(doc(codesRef(), USER_CODES))).data()
  let code = uid.slice(0, 8).toUpperCase()
  if (userCodes && userCodes[code] && userCodes[code] !== uid) {
    code = uid.slice(1, 9).toUpperCase()
  }
  let data = {}
  data[code] = uid
  await setDoc(doc(codesRef(), USER_CODES), data, { merge: true })
  return code
}

export const getValidCodes = async (): Promise<{ [key: string]: string }> => {
  return (await getDoc(doc(codesRef(), USER_CODES))).data()
}

export const subscribe = async (
  priceId: string,
  uid: string,
  stripe: Stripe,
  organisation: string,
) => {
  const docRef = await addDoc(
    collection(getFirestore(app), `stripe_customers/${uid}/checkout_sessions`),
    {
      price: priceId,
      mode: "subscription",
      allow_promotion_codes: false,
      success_url: `${window.location.origin}/app/organisations/${organisation}/settings`,
      cancel_url: `${window.location.origin}/app/organisations/${organisation}/subscription`,
    },
  )

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(docRef, snap => {
    const { sessionId } = snap.data()
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      stripe.redirectToCheckout({ sessionId })
    }
  })
}

export const updateSubscription = (
  price: Partial<StripePrice>,
  subscriptionData: UserSubscription,
) => {
  const functionRef = httpsCallable(getFunctions(app, functionLocation), "updateSubscription")

  return functionRef({
    subscriptionId: subscriptionData.id,
    updateData: {
      proration_behavior: "create_prorations",
      cancel_at_period_end: false,
      items: [
        {
          id: subscriptionData.items[0].id,
          price: price.id,
        },
      ],
    },
  })
}

export const fetchProducts = async (organisation: string) => {
  const newProducts: StripeProduct[] = []
  const productsDocs = (
    await getDocs(
      query(
        collection(getFirestore(app), "stripe_products"),
        where("active", "==", true),
        where("stripe_metadata_liveMode", "==", "true"),
        where("stripe_metadata_organisation", "==", organisation),
      ),
    )
  ).docs
  await Promise.all(
    productsDocs.map(async doc => {
      const p = doc.data() as StripeProduct
      p.id = doc.id
      p.prices = []
      const pricesDocs = (
        await getDocs(
          query(collection(getFirestore(app), doc.ref.path, "prices"), where("active", "==", true)),
        )
      ).docs
      await Promise.all(
        pricesDocs.map(priceDoc => {
          const pr = priceDoc.data() as StripePrice
          pr.id = priceDoc.id
          p.prices.push(pr)
        }),
      )
      newProducts.push(p)
    }),
  )
  return newProducts
}

export const getUserSubscription = (
  uid: string,
  actionSubscription: (s: UserSubscription | null) => void,
  actionNoSubscription: () => void,
) => {
  return onSnapshot(
    query(
      collection(getFirestore(app), `stripe_customers/${uid}/subscriptions`),
      where("status", "!=", "canceled"),
    ),
    async querySnapshot => {
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0]
        const subscription = doc.data() as UserSubscription
        subscription.id = doc.id
        const productData = (await subscription.product.get()).data() as StripeProduct
        subscription.productData = productData
        actionSubscription && actionSubscription(subscription)
      } else {
        actionNoSubscription && actionNoSubscription()
      }
    },
  )
}
