import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  DocumentSnapshot,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore"
import { Organisation, UserOrgData } from "./model"
import { defaultUserFields } from "../components/admin/defaultUserFields"

const orgsRef = () => collection(getFirestore(app), "organisations")
const MEMBERS = "members"
const MEMBERS_DATA = "membersData"

export const fetchOrganisation = async (slug: string) => {
  const docSnapshot = await getDoc(doc(orgsRef(), slug))
  const organisationData = docSnapshot.data() as Organisation
  return organisationData
}

export const fetchOrganisations = async (slugs: string[]) => {
  const organisations = await Promise.all(slugs.map(slug => fetchOrganisation(slug)))
  return organisations
}

export const subscribeToOrganisations = (
  slugs: string[],
  actionSuccess: (orgs: Organisation[]) => void,
  actionError: (e: string) => void,
) => {
  if (slugs.length === 0) {
    return
  }
  return onSnapshot(
    query(orgsRef(), where("slug", "in", slugs)),
    snap => {
      const organisations: Organisation[] = []
      snap.forEach(doc => {
        const org = { userFields: defaultUserFields, ...doc.data() } as Organisation
        organisations.push(org)
      })
      actionSuccess(organisations)
    },
    error => {
      actionError ? actionError(error.message) : console.log(error)
    },
  )
}

export const updateOrganisationData = (
  organisationSlug: string,
  updateData: Partial<Organisation>,
  actionSuccess?: () => void,
  actionError?: () => void,
) => {
  updateDoc(doc(orgsRef(), organisationSlug), updateData)
    .then(actionSuccess)
    .catch(actionError || console.log)
}

export const joinOrganisation = async (uid: string, organisation: Organisation) => {
  return await setDoc(doc(orgsRef(), organisation.slug, MEMBERS, uid), {
    uid,
    status: organisation.config?.requires_approval ? "pending" : "active",
  })
}

export const getMemberData = async (uid: string, organisationSlug: string) => {
  const docSnapshot = await getDoc(doc(orgsRef(), organisationSlug, MEMBERS_DATA, uid))
  const memberData = {
    ...docSnapshot.data(),
    organisation: organisationSlug,
    userId: docSnapshot.id,
  } as UserOrgData
  return memberData
}

export const fetchMember = async (organisationSlug: string, uid: string) => {
  const member = (await getDoc(doc(orgsRef(), organisationSlug, MEMBERS, uid))).data()
  return member
}

export const fetchMembers = async (
  organisationSlug: string,
  options?: { includePending?: boolean; onlyPending?: boolean },
) => {
  let querySnapshot: QuerySnapshot<DocumentData>
  const queryRef = collection(orgsRef(), organisationSlug, MEMBERS)
  if (options?.onlyPending) {
    querySnapshot = await getDocs(query(queryRef, where("status", "==", "pending")))
  } else if (options?.includePending) {
    querySnapshot = await getDocs(query(queryRef, where("status", "!=", "pending")))
  } else {
    querySnapshot = await getDocs(queryRef)
  }
  const members: string[] = []
  querySnapshot.forEach(doc => {
    members.push(doc.id)
  })
  return members
}

export const fetchMembersData = async (organisationSlug: string, orgMembers?: string[]) => {
  let querySnapshot: DocumentSnapshot<DocumentData>[] | QuerySnapshot<DocumentData>
  if (orgMembers) {
    if (orgMembers.length === 0) {
      return []
    }
    querySnapshot = await Promise.all(
      orgMembers.map(uid => getDoc(doc(orgsRef(), organisationSlug, MEMBERS_DATA, uid))),
    )
  } else {
    querySnapshot = await getDocs(collection(orgsRef(), organisationSlug, MEMBERS_DATA))
  }
  const membersData: UserOrgData[] = []
  querySnapshot.forEach(doc => {
    const memberData = {
      ...doc.data(),
      organisation: organisationSlug,
      userId: doc.id,
    } as UserOrgData
    membersData.push(memberData)
  })
  return membersData
}

export const updateMemberData = async (
  uid: string,
  organisationSlug: string,
  dataUpdate: Partial<UserOrgData>,
) => {
  try {
    await updateDoc(doc(orgsRef(), organisationSlug, MEMBERS_DATA, uid), {
      ...dataUpdate,
      organisation: organisationSlug,
      userId: uid,
    })
  } catch (e) {
    if (e.code === "not-found") {
      await setDoc(
        doc(orgsRef(), organisationSlug, MEMBERS_DATA, uid),
        { ...dataUpdate, organisation: organisationSlug, userId: uid },
        { merge: true },
      )
    } else {
      console.log("uh oh", e)
    }
  }
}

export const updateMemberStatus = async (uid: string, organisationSlug: string, status: string) => {
  return await updateDoc(doc(orgsRef(), organisationSlug, MEMBERS, uid), { status })
}

export const subscribeToMemberData = (
  uid: string,
  organisationSlug: string,
  actionSucess: (memberData: UserOrgData) => void,
) => {
  onSnapshot(doc(orgsRef(), organisationSlug, MEMBERS_DATA, uid), docSnap => {
    const memberData = {
      ...docSnap.data(),
      organisation: organisationSlug,
      userId: docSnap.id,
    } as UserOrgData
    actionSucess(memberData)
  })
}
