import * as React from "react"

interface TooltipSpanProps extends React.HTMLAttributes<HTMLSpanElement> {
  text: string
  children: React.ReactNode
  className?: string
  size?: string
  placement?: string
}

export const TooltipSpan = ({
  text,
  children,
  className,
  size,
  placement,
  ...rest
}: TooltipSpanProps): JSX.Element => {
  /*
  A basic tooltip on a span component
  * text: the tooltip's text
  * children: the span's content
  * className: classes for the span
  * size: tooltip size (from xs to xl)
  * placement: tooltip placement (left, right or center)
  */

  // Define the text size
  const textSize = {
    xs: "before:text-xs",
    sm: "before:text-sm",
    base: "before:text-base",
    lg: "before:text-lg",
    xl: "before:text-xl",
  }[size || "xs"]

  // Define the position (left, center, right)
  const posX = {
    left: "before:left-0",
    center: "before:-translate-x-1/2 before:left-1/2",
    right: "before:right-0",
  }[placement || "center"]

  // Return a span with a tooltip on hover
  return (
    <span
      data-tooltip={text}
      className={`
      relative
      before:absolute before:!content-[attr(data-tooltip)] dark:before:!content-[attr(data-tooltip)] ${textSize} before:bg-zinc-200 dark:before:bg-zinc-700
      before:px-1.5 before:py-0.5 before:rounded before:max-w-[220px] before:w-max before:-top-1 before:-translate-y-full before:text-zinc-900 dark:before:text-zinc-100
      ${posX} before:hidden hover:before:block
      ${className}
      `}
      {...rest}
    >
      {children}
    </span>
  )
}
