import * as React from "react"
import { Fragment } from "react"
import { Listbox, Transition } from "@headlessui/react"

interface BasicDropdownProps {
  value: string
  setValue: React.Dispatch<string>
  options: Array<
    | string
    | {
        value: string
        label: string
        imageUrl?: string
      }
  >
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  shadow?: boolean
  placeholder?: string
}

export const BasicDropdown = ({
  value,
  setValue,
  options,
  size = "md",
  shadow = true,
  placeholder = "Select an option",
}: BasicDropdownProps): JSX.Element => {
  /**
  A dropdown based on headless ui with a set of options
  * value is the current value
  * setValue sets the current value
  * options are an Array of unique values
  */

  const textSize = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-md",
    lg: "text-lg",
    xl: "text-xl",
  }[size]

  const paddingY = {
    xs: "py-0.5",
    sm: "py-0.5",
    md: "py-2",
    lg: "py-2.5",
    xl: "py-3",
  }[size]

  const paddingL1 = {
    xs: "pl-1",
    sm: "pl-1.5",
    md: "pl-3",
    lg: "pl-4",
    xl: "pl-4",
  }[size]

  const paddingR1 = {
    xs: "pr-6",
    sm: "pr-6",
    md: "pr-10",
    lg: "pr-12",
    xl: "pr-12",
  }[size]

  const paddingR2 = {
    xs: "pr-0.5",
    sm: "pr-0.5",
    md: "pr-2",
    lg: "pr-2.5",
    xl: "pr-3",
  }[size]

  const shadowClass = shadow ? "shadow-md" : ""

  const normalisedOptions = options.map(o => (typeof o === "string" ? { value: o, label: o } : o))
  const selectedOption = normalisedOptions.filter(o => o.value === value)[0]

  // Listbox is the Headless UI dropdown equivalent
  return (
    <Listbox value={value} onChange={setValue}>
      <div className="relative mt-1 text-zinc-800 dark:text-zinc-200 flex-1">
        {/* Dropdown button */}
        <Listbox.Button
          className={`
            relative input text-left ${paddingY} ${paddingL1} ${paddingR1} ${textSize} ${shadowClass}
          `}
        >
          {/* Display the currently selected value */}
          <span
            className={`flex truncate items-center ${
              selectedOption ? "" : "text-zinc-600 dark:text-zinc-400"
            }`}
          >
            {selectedOption?.imageUrl && (
              <img
                src={selectedOption.imageUrl}
                className="w-6 h-6 mr-1 object-contain object-center"
              />
            )}
            {selectedOption?.label || placeholder}
          </span>
          <span
            className={`absolute inset-y-0 right-0 flex items-center ${paddingR2} pointer-events-none`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </Listbox.Button>
        {/* Fade in the options menu */}
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Options menu */}
          <Listbox.Options
            className={`absolute overflow-auto w-full max-h-60 py-1 mt-1 text-base bg-zinc-50 dark:bg-zinc-900
            rounded shadow-lg border border-zinc-300 dark:border-zinc-800 z-10 thin-scrollbar`}
          >
            {/* Map each option in the input array */}
            {normalisedOptions.map((option, idx) => (
              // Style based on `active` (background) and `selected` (check mark)
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `
                  ${active ? "bg-secondary-400/20 dark:bg-secondary-600/20" : ""}
                  cursor-default select-none relative text-zinc-800 dark:text-zinc-200
                  ${paddingY} ${paddingL1} ${paddingR1} ${textSize}
                `
                }
                value={option.value}
              >
                {({ selected, active }) => (
                  <>
                    {/* Label */}
                    <span
                      className={`${
                        selected ? "font-medium" : "font-normal"
                      } flex truncate items-center`}
                    >
                      {option.imageUrl && (
                        <img
                          src={option.imageUrl}
                          className="w-6 h-6 mr-1 object-contain object-center"
                        />
                      )}
                      {option.label}
                    </span>
                    {/* Check mark if selected*/}
                    {selected ? (
                      <span
                        className={`text-secondary absolute inset-y-0 right-0 flex items-center ${paddingR2}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
