import * as React from "react"
import { useEffect, useState, useMemo, Dispatch } from "react"
import { DropdownMenu } from "./dropdownMenu"

interface MultiSelectDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  options: string[]
  value: string[]
  setValue: Dispatch<string[]>
  placeholder: string
  maxSelected?: number
  tabIndex?: number
  reverseOrder?: boolean
}

export const MultiSelectDropdown = ({
  options,
  value,
  setValue,
  placeholder,
  maxSelected,
  tabIndex,
  reverseOrder,
  ...others
}: MultiSelectDropdownProps): JSX.Element => {
  /*
  A Multiselect Dropdown
  * options is an Array of options (string)
  * value is the current list of selected options
  * setValue sets the selected options
  * placeholder sets the search input placeholder, defaults to "Search something"
  * maxSelected is the maximum number of options in the multiselect
  * tabIndex is the eponymous html attribute
  * reverseOrder set to true places the selected values beneath the search input
  */

  const [searchValue, setSearchValue] = useState("")
  const [activeOption, setActiveOption] = useState(0)

  // Adds a value to the multiselect and resets the search input
  const setAll = option => {
    setSearchValue("")
    if (setValue) {
      if (value.indexOf(option) >= 0) {
        setValue(value.filter(v => v !== option))
      } else if (maxSelected == undefined || (maxSelected && value.length < maxSelected)) {
        setValue([...value, option])
      }
    }
  }

  // Set filtered options based on the current search input, ordered as follows:
  // * First the options starting with the same letters as the search (case insensitive)
  // * Second the options that contain the same letters as the search (case insensitive)
  const filteredOptions = useMemo(
    () =>
      searchValue
        ? [
            ...options.filter(o => o.toLowerCase().startsWith(searchValue.toLowerCase())),
            ...options.filter(
              o =>
                o.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 &&
                !o.toLowerCase().startsWith(searchValue.toLowerCase()),
            ),
          ]
        : options,
    [options, searchValue],
  )

  // Reset the active option to the first one when the search input changes
  useEffect(() => {
    if (searchValue) {
      setActiveOption(0)
    }
  }, [searchValue])

  // Show the selected options as removable tags as well as a "Clear all" button
  const renderSelected = () => {
    return (
      <div className={reverseOrder ? "mt-2" : "mb-2"}>
        {/* Map the options in value */}
        {value.map(val => {
          // For each selected option render a tag with a close button to remove from the selected options
          return (
            <div key={val} className="tag">
              <button
                onClick={e => {
                  e.preventDefault()
                  setValue(value.filter(v => v !== val))
                }}
                className="-ml-1 px-1 mr-1 border-r border-primary-300 dark:border-primary-650"
                tabIndex={tabIndex}
              >
                ×
              </button>
              {val}
            </div>
          )
        })}
        {/* Button to clear the multiselect value */}
        <button
          className="btn btn-danger xs ml-auto"
          onClick={e => {
            e.preventDefault()
            setValue([])
          }}
          tabIndex={tabIndex}
        >
          Clear all
        </button>
      </div>
    )
  }

  return (
    <div {...others}>
      {/* Show the selected options at the top */}
      {value && value.length > 0 && !reverseOrder && renderSelected()}
      <div className="relative">
        <div className="flex items-center relative">
          {/* Search input, prevent default on Enter press */}
          <input
            className="input relative peer pr-10"
            value={searchValue}
            type="text"
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={e => {
              e.key === "Enter" && e.preventDefault()
            }}
            placeholder={placeholder || "Search something"}
            tabIndex={tabIndex}
          />
          {/* Up-down carets */}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-zinc-500"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden={true}
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          {/* Options menu to navigate */}
          <DropdownMenu
            options={filteredOptions}
            setAll={setAll}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            renderOption={o => <span>{o}</span>}
            isOptionSelected={o => value && value.indexOf(o) >= 0}
            isOptionDisabled={o =>
              maxSelected && value && value.length >= maxSelected && value.indexOf(o) < 0
            }
          />
        </div>
      </div>
      {/* Show the selected options at the bottom */}
      {value && value.length > 0 && reverseOrder && renderSelected()}
    </div>
  )
}
