import { Field, Section, UserFields } from "../../business_logic/model"
import skills from "../../data/skills.json"
import industries from "../../data/industries.json"
import interests from "../../data/hobbies.json"
import { sortStr } from "../../utils/utils"

const defaultFields: Field[] = [
  {
    id: "skills",
    name: "Skills",
    type: "Multi-Select",
    options: (
      Object.values(skills).reduce(
        (previous: string[], next: string[]) => [...previous, ...next],
        [],
      ) as string[]
    ).sort((a, b) => sortStr(b, a)),
    maxSelected: 10,
    mandatory: true,
    displayStyle: "Tags",
    showOnUserCard: true,
    showOnUserProfile: true,
    usedInMatching: true,
    matchingType: "Most common",
    matchingWeight: 10,
    searchable: true,
  },
  {
    id: "industries",
    name: "Industries",
    type: "Multi-Select",
    options: industries,
    maxSelected: 5,
    mandatory: true,
    displayStyle: "Tags",
    showOnUserCard: true,
    showOnUserProfile: true,
    usedInMatching: true,
    matchingType: "Most common",
    matchingWeight: 6,
    searchable: true,
  },
  {
    id: "interests",
    name: "Interests",
    type: "Multi-Select",
    options: interests,
    maxSelected: 10,
    displayStyle: "Tags",
    showOnUserCard: false,
    showOnUserProfile: true,
    displayLabel: "💡 Interests",
    usedInMatching: true,
    matchingType: "Most common",
    matchingWeight: 3,
  },
  {
    id: "working_on",
    name: "I am currently working on",
    type: "Input",
    showOnUserCard: false,
    showOnUserProfile: true,
    displayLabel: "🔨 I am currently working on",
  },
  {
    id: "passionate_about",
    name: "I am passionate about",
    type: "Input",
    showOnUserCard: false,
    showOnUserProfile: true,
    displayLabel: "❤️ I am passionate about",
  },
  {
    id: "good_at",
    name: "I am pretty good at",
    type: "Input",
    showOnUserCard: false,
    showOnUserProfile: true,
    displayLabel: "💪 I am pretty good at",
  },
  {
    id: "fun_fact",
    name: "One fun fact about me is",
    type: "Input",
    showOnUserCard: true,
    showOnUserProfile: true,
    displayLabel: "😄 One fun fact about me is",
  },
  {
    id: "chat_about",
    name: "I am happy to chat with you about",
    type: "Input",
    showOnUserCard: false,
    showOnUserProfile: true,
    displayLabel: "💬 I am happy to chat with you about",
  },
  {
    id: "portfolio",
    name: "Project/Portfolio",
    type: "Multi-Input",
    columns: ["Link", "Name"],
    showOnUserCard: false,
    showOnUserProfile: true,
    displayStyle: "Table",
    displayLabel: "💼 Project/Portfolio",
  },
]

const defaultSections: { [key: string]: Section } = {
  "000001": {
    id: "000001",
    name: "Skills",
    fieldsIds: ["skills"],
  },
  "000002": {
    id: "000002",
    name: "Industries",
    fieldsIds: ["industries"],
  },
  "000003": {
    id: "000003",
    name: "More about me",
    fieldsIds: [
      "interests",
      "working_on",
      "passionate_about",
      "good_at",
      "fun_fact",
      "chat_about",
      "portfolio",
    ],
  },
}

export const defaultUserFields: UserFields = {
  fields: defaultFields,
  sections: defaultSections,
  sectionOrder: ["000001", "000002", "000003"],
  platformFields: [
    { value: "firstName", label: "First Name", active: true },
    { value: "lastName", label: "Last Name", active: true },
    { value: "headline", label: "Headline", active: true },
    { value: "location", label: "Location", active: true },
    { value: "availability", label: "General availability", active: true },
    { value: "description", label: "Mini bio", active: true },
    { value: "openTo", label: "Open to", active: true },
    { value: "lookingFor", label: "Match preferences", active: true },
    { value: "linkedin", label: "Linkedin", active: false },
    { value: "phoneNumber", label: "Phone number", active: true },
    { value: "notificationPreferences", label: "Notification preferences", active: true },
  ],
  similarityFields: [
    { fieldId: "skills", prefix: "have", suffix: "skills" },
    { fieldId: "industries", prefix: "have worked in the", suffix: "industry" },
    { fieldId: "interests", prefix: "like", suffix: "" },
  ],
}

export const displayStyles = {
  Input: ["Simple text", "Paragraph"],
  "Multi-Input": ["List", "Table"],
  Textarea: ["Paragraph", "Simple text"],
  Select: ["Item"],
  Radio: ["Item"],
  "Multi-Select": ["Tags", "Comma-separated"],
  Checklist: ["Tags", "Comma-separated"],
  "Checklist + Sub-options": ["Tags", "Comma-separated"],
}

export const defaultFieldValue = {
  Input: "",
  Textarea: "",
  "Multi-Input": [],
  Select: "",
  Radio: "",
  "Multi-Select": [],
  Checklist: [],
  "Checklist + Sub-options": {},
}
