import * as React from "react"
import { NotifierContextProvider } from "react-headless-notifier"
import { Provider } from "jotai"
import { DataSubscriptions } from "./src/context"
import "./src/css/index.css"
import "react-international-phone/style.css"

export const wrapRootElement = ({ element }) => {
  return (
    <Provider>
      <NotifierContextProvider
        config={{
          max: null,
          duration: 8000,
          position: "topRight",
        }}
      >
        {element}
      </NotifierContextProvider>
      <DataSubscriptions />
    </Provider>
  )
}

export const onClientEntry = () => {
  // all inspiringchats.com requests should be redirected
  // this is a quick hack to rediret by setting a new `window.location`
  if (
    // it's supposed to be link to a firebfase function
    window.location.hostname == "inspiringchats.com" &&
    window.location.pathname.startsWith("/australia-southeast1")
  ) {
    const newPath = window.location.pathname.replace("/australia-southeast1", "")
    window.location = `https://australia-southeast1-inspiring-chats.cloudfunctions.net${newPath}`
  } else if (
    // it could also be a legacy (pre-rebrand) non-app link
    window.location.hostname == "inspiringchats.com" &&
    !window.location.pathname.startsWith("/app/") &&
    !window.location.pathname.startsWith("/about") &&
    !window.location.pathname.startsWith("/disclaimer") &&
    !window.location.pathname.startsWith("/forgot-password") &&
    !window.location.pathname.startsWith("/linkedin") &&
    !window.location.pathname.startsWith("/privacy-policy") &&
    !window.location.pathname.startsWith("/signup") &&
    !window.location.pathname.startsWith("/terms-of-use") &&
    !window.location.pathname.startsWith("/login")
  ) {
    window.location = "https://www.inspiringchats.com/"
  } else if (
    // it must be an in-app link
    window.location.hostname == "inspiringchats.com"
  ) {
    window.location = `https://app.bekko.io${window.location.pathname}${window.location.search}${window.location.hash}`
  }
}
