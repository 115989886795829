import * as React from "react"
import { useState } from "react"
import { BasicPopover, SuccessNotification } from "."
import { sortStr } from "../../utils/utils"
import industriesList from "../../data/industries.json"
import skillsList from "../../data/skills.json"
import { sendSuggestion } from "../../business_logic/leads"
import { User, Experience } from "../../business_logic/model"
import { useNotifier } from "react-headless-notifier"
import { ErrorNotification } from "./notification"

export const maxSkills = 10
export const maxIndustries = 5

interface Option {
  label: string
  value: string
  tag?: string
}

let skillsOptions = []
Object.entries(skillsList).forEach(([skillGroup, skillGroupList]: [string, string[]]) => {
  skillsOptions = skillsOptions.concat(
    skillGroupList.map(option => {
      return { label: option, value: option, tag: skillGroup }
    }),
  )
})
skillsOptions = skillsOptions.sort((a, b) => sortStr(b.value, a.value))

const experienceOptions = ["1-2", "3-4", "5+"] as Experience[]

interface ChecklistProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option[]
  value: string[]
  setValue: React.Dispatch<string[]>
  maxSelected?: boolean
  hasSearch?: boolean
  placeholder?: string
  clearButton?: boolean
  tabIndex?: number
  noColumns?: boolean
  maxHeight?: number | string
}

export const Checklist = ({
  options,
  maxSelected,
  value,
  setValue,
  hasSearch,
  placeholder,
  clearButton,
  tabIndex,
  noColumns,
  maxHeight = 500,
  ...others
}: ChecklistProps): JSX.Element => {
  /*
  A checklist with the option to set experience for each option selected
  * options: Checklist options
  * maxSelected: Whether the maximum number of options selected is reached
  * value: Current checklist state
  * setValue: Sets the current state
  * hasSearch: Whether the checklist has a search box
  * placeholder: Placeholder in the search input
  * addExperience: Whether to add experience for each selected option
  * clearButton: Whether to show a button to clear all
  * tabIndex: Tab index (to allow skippable checklist)
  * maxHeight: Maximum checklist height after which it becomes scrollable
  */

  // Search input and filtered options based on search input
  const [search, setSearch] = useState("")
  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleSearch = event => {
    const newSearch = event.target.value.toLowerCase()
    setSearch(newSearch)
    setFilteredOptions(
      options.filter(
        option =>
          option.label.toLowerCase().indexOf(newSearch) >= 0 ||
          (option.tag && option.tag.toLowerCase().indexOf(newSearch) >= 0),
      ),
    )
  }

  return (
    <div className="relative thin-scrollbar pr-6" {...others}>
      {hasSearch && (
        // Search input
        <div className="form-group flex items-center pr-2 relative">
          <div className="absolute left-1.5 z-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
          <input
            value={search}
            placeholder={placeholder}
            onChange={handleSearch}
            className="input px-8"
            type="text"
            tabIndex={tabIndex}
          />
        </div>
      )}
      {clearButton && (
        // Clear all button
        <button
          className={
            value && Object.keys(value).length > 0
              ? `
              text-white bg-danger hover:bg-danger-650 dark:hover:bg-danger-400
              absolute origin-top-right right-0 p-0 w-5 h-5 font-mono z-10 rounded-sm text-lg leading-5
              ${hasSearch ? "top-12" : "top-1"}
            `
              : "hidden"
          }
          onClick={e => {
            e.preventDefault()
            setValue([])
          }}
          tabIndex={tabIndex}
        >
          ×
        </button>
      )}
      {filteredOptions.length > 0 ? (
        // Options
        <div
          className="relative overflow-x-hidden overflow-y-auto"
          style={{ maxHeight: maxHeight }}
        >
          <div className={`${noColumns ? "flex flex-wrap" : "columns-xs"} gap-4`}>
            {filteredOptions.map((option, index) => (
              <CheklistOption
                option={option}
                index={index}
                value={value || []}
                setValue={setValue}
                tabIndex={tabIndex}
                maxSelected={maxSelected}
                key={`option-${option.value}`}
              />
            ))}
          </div>
        </div>
      ) : (
        "No result fit your search"
      )}
    </div>
  )
}

interface CheklistOptionProps extends React.HTMLAttributes<HTMLDivElement> {
  option: Option
  index: number
  value: string[]
  setValue: React.Dispatch<string[]>
  tabIndex: number
  maxSelected: boolean
}

const CheklistOption = ({
  option,
  index,
  value,
  setValue,
  tabIndex,
  maxSelected,
  ...others
}: CheklistOptionProps): JSX.Element => {
  /*
  A checklist option  with the possibility to set the experience
  * option: Checklist option
  * index: Option index
  * value: Current checklist state
  * setValue: Sets the current state
  * tabIndex: Tab index (to allow skippable checklist)
  * maxSelected: Whether the maximum number of options selected is reached
  */

  // Check whether the option is selected
  const checked = value && value.includes(option.value)
  // Check whether the option is disabled (max number of option is reached and option is not selected)
  const disabled = maxSelected ? Object.keys(value).indexOf(option.value) < 0 : false

  // Function to add an option to the value
  const addValue = (value: string[], option: Option) => {
    let addedValue = [...value]
    addedValue.push(option.value)
    return addedValue
  }

  // Function to remove an option to the value
  const removeValue = (value: string[], option: Option) => {
    return value.filter(x => x !== option.value)
  }

  return (
    <div key={option.value} style={{ breakInside: "avoid" }} className="pl-2" {...others}>
      {/* Checkbox and label */}
      <label className="text-base text-black dark:text-zinc-200">
        <input
          type="checkbox"
          key={index}
          className="mr-3 text-primary rounded"
          checked={checked}
          disabled={disabled}
          onChange={e => {
            e.target.checked
              ? setValue(addValue(value, option))
              : setValue(removeValue(value, option))
          }}
          tabIndex={tabIndex}
        />
        {option.label}
      </label>
    </div>
  )
}

interface ChecklistSuboptionsProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option[]
  value: { [key: string]: string }
  setValue: React.Dispatch<{ [key: string]: string }>
  maxSelected?: boolean
  hasSearch?: boolean
  placeholder?: string
  clearButton?: boolean
  tabIndex?: number
  maxHeight?: number
  suboptionsLabel?: string
  suboptionsOptions?: string[]
}

export const ChecklistSuboptions = ({
  options,
  maxSelected,
  value,
  setValue,
  hasSearch,
  placeholder,
  clearButton,
  tabIndex,
  maxHeight = 500,
  suboptionsLabel = "Years of experience",
  suboptionsOptions = experienceOptions,
  ...others
}: ChecklistSuboptionsProps): JSX.Element => {
  /*
  A checklist with the option to set experience for each option selected
  * options: Checklist options
  * maxSelected: Whether the maximum number of options selected is reached
  * value: Current checklist state
  * setValue: Sets the current state
  * hasSearch: Whether the checklist has a search box
  * placeholder: Placeholder in the search input
  * addExperience: Whether to add experience for each selected option
  * clearButton: Whether to show a button to clear all
  * tabIndex: Tab index (to allow skippable checklist)
  * maxHeight: Maximum checklist height after which it becomes scrollable
  */

  // Search input and filtered options based on search input
  const [search, setSearch] = useState("")
  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleSearch = event => {
    const newSearch = event.target.value.toLowerCase()
    setSearch(newSearch)
    setFilteredOptions(
      options.filter(
        option =>
          option.label.toLowerCase().indexOf(newSearch) >= 0 ||
          (option.tag && option.tag.toLowerCase().indexOf(newSearch) >= 0),
      ),
    )
  }

  return (
    <div className="relative thin-scrollbar pr-6" {...others}>
      {hasSearch && (
        // Search input
        <div className="form-group flex items-center pr-2 relative isolate">
          <div className="absolute left-1.5 z-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
          <input
            value={search}
            placeholder={placeholder}
            onChange={handleSearch}
            className="input px-8"
            type="text"
            tabIndex={tabIndex}
          />
        </div>
      )}
      {clearButton && (
        // Clear all button
        <button
          className={
            value && Object.keys(value).length > 0
              ? `
              text-white bg-danger hover:bg-danger-650 dark:hover:bg-danger-400
              absolute origin-top-right right-0 p-0 w-5 h-5 font-mono z-10 rounded-sm text-lg leading-5
              ${hasSearch ? "top-12" : "top-1"}
            `
              : "hidden"
          }
          onClick={e => {
            e.preventDefault()
            setValue({})
          }}
          tabIndex={tabIndex}
        >
          ×
        </button>
      )}
      {filteredOptions.length > 0 ? (
        // Options
        <div
          className="relative overflow-x-hidden overflow-y-auto"
          style={{ maxHeight: maxHeight }}
        >
          <div className="columns-xs gap-4">
            {filteredOptions.map((option, index) => (
              <CheklistSuboptionsOption
                option={option}
                index={index}
                value={value}
                setValue={setValue}
                tabIndex={tabIndex}
                maxSelected={maxSelected}
                suboptionsLabel={suboptionsLabel}
                suboptionsOptions={suboptionsOptions}
              />
            ))}
          </div>
        </div>
      ) : (
        "No result fit your search"
      )}
    </div>
  )
}

interface CheklistSuboptionsOptionProps extends React.HTMLAttributes<HTMLDivElement> {
  option: Option
  index: number
  value: { [key: string]: string }
  setValue: React.Dispatch<{ [key: string]: string }>
  tabIndex: number
  maxSelected: boolean
  suboptionsLabel?: string
  suboptionsOptions?: string[]
}

const CheklistSuboptionsOption = ({
  option,
  index,
  value,
  setValue,
  tabIndex,
  maxSelected,
  suboptionsLabel = "Years of experience",
  suboptionsOptions = experienceOptions,
  ...others
}: CheklistSuboptionsOptionProps): JSX.Element => {
  /*
  A checklist option  with the possibility to set the experience
  * option: Checklist option
  * index: Option index
  * value: Current checklist state
  * setValue: Sets the current state
  * tabIndex: Tab index (to allow skippable checklist)
  * addExperience: Whether to add experience for each selected option
  * maxSelected: Whether the maximum number of options selected is reached
  */

  // Check whether the option is selected
  const checked = value && Object.keys(value).includes(option.value)
  // Check whether the option is disabled (max number of option is reached and option is not selected)
  const disabled = maxSelected ? Object.keys(value).indexOf(option.value) < 0 : false

  // Function to add an option to the value
  const addValue = (value: { [key: string]: string }, option: Option) => {
    let addedValue = { ...value }
    addedValue[option.value] = "1-2"
    return addedValue
  }

  // Function to remove an option to the value
  const removeValue = (value: { [key: string]: string }, option: Option) => {
    let removedValue = {}
    Object.keys(value).forEach(x => {
      if (x !== option.value) {
        removedValue[x] = value[x]
      }
    })
    return removedValue
  }

  // Update the option's suboption
  const updateValue = (value: { [key: string]: string }, option: Option, experience: string) => {
    let updatedValue = { ...value }
    updatedValue[option.value] = experience
    return updatedValue
  }

  return (
    <div key={option.value} style={{ breakInside: "avoid" }} className="pl-2" {...others}>
      {/* Checkbox and label */}
      <label className="text-base text-black dark:text-zinc-200">
        <input
          type="checkbox"
          key={index}
          className="mr-3 text-primary rounded"
          checked={checked}
          disabled={disabled}
          onChange={e => {
            e.target.checked
              ? setValue(addValue(value, option))
              : setValue(removeValue(value, option))
          }}
          tabIndex={tabIndex}
        />
        {option.label}
      </label>
      <br />
      {/* Experience radio buttons, if required */}
      {checked && (
        <div className="border-l-2 border-primary transform -translate-y-2 pt-1">
          <div className="text-zinc-700 dark:text-zinc-400 ml-6 text-sm">{suboptionsLabel}</div>
          <div className="ml-6">
            {suboptionsOptions.map(exp => (
              <label className="text-sm mr-4">
                <input
                  name={"experience" + option.value}
                  type="radio"
                  checked={value[option.value] === exp}
                  onChange={e => e.target.checked && setValue(updateValue(value, option, exp))}
                  className="mr-2 text-primary"
                  tabIndex={tabIndex}
                />
                {exp}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

type ChecklistWithSuggestionsProps = (ChecklistProps | ChecklistSuboptionsProps) & {
  user: User
  suggestionTag: string
  suggestionLabel: string
}

export const ChecklistWithSuggestions = ({
  user,
  options,
  value,
  setValue,
  maxSelected,
  hasSearch,
  placeholder,
  clearButton,
  tabIndex,
  maxHeight = 500,
  suggestionTag,
  suggestionLabel,
  ...others
}: ChecklistWithSuggestionsProps): JSX.Element => {
  /*
  A checklist with the possibility to send suggestions on additional options
  * user: Current user
  * options: Checklist options
  * value: Current checklist state
  * setValue: Sets the current state
  * maxSelected: Whether the maximum number of options selected is reached
  * hasSearch: Whether the checklist has a search box
  * placeholder: Placeholder in the search input
  * addExperience: Whether to add experience for each selected option
  * clearButton: Whether to show a button to clear all
  * tabIndex: Tab index (to allow skippable checklist)
  * maxHeight: Maximujm checklist height after which it becomes scrollable
  */

  const [suggestions, setSuggestions] = useState("")
  const { notify } = useNotifier()

  const checklistProps = {
    options,
    clearButton,
    maxSelected,
    hasSearch,
    placeholder,
    setValue,
    value,
    maxHeight,
    tabIndex,
  }

  return (
    <div className="relative" {...others}>
      {/* Checklist */}
      {Array.isArray(value) ? (
        <Checklist {...checklistProps} />
      ) : (
        <ChecklistSuboptions {...checklistProps} />
      )}
      {user && (
        <div className=" absolute right-2 top-2">
          {/* Popover adding a suggestion on confirm */}
          <BasicPopover
            trigger="+"
            btnClassName="btn btn-primary xs"
            confirmButtonClassName="btn btn-primary sm"
            actionConfirm={() => {
              sendSuggestion(
                `${user.firstName} ${user.lastName}`,
                user.email,
                suggestions,
                suggestionTag,
                () => {
                  setSuggestions("")
                  notify(
                    <SuccessNotification message="Thanks for your suggestion, we'll try to add it as soon as possible!" />,
                  )
                },
                errorMessage => {
                  notify(
                    <ErrorNotification message={errorMessage} title="Something went wrong..." />,
                  )
                },
              )
            }}
            confirmButton="Send Suggestions"
            cancelButton="Cancel"
            align="right"
          >
            <div style={{ maxWidth: 250 }} className="">
              {/* Message prompt */}
              <div className="mb-2 text-zinc-800 dark:text-zinc-200">{suggestionLabel}</div>
              {/* Suggestion textarea */}
              <textarea
                className="input"
                rows={2}
                value={suggestions}
                onChange={e => setSuggestions(e.target.value)}
              />
            </div>
          </BasicPopover>
        </div>
      )}
    </div>
  )
}

interface ChecklistSetOptionsProps extends React.HTMLAttributes<HTMLDivElement> {
  user: User
  value: { [key: string]: Experience }
  setValue: React.Dispatch<{ [key: string]: Experience }>
  hasSearch: boolean
  maxHeight?: number
  tabIndex?: number
  addExperience?: boolean
}

export const SkillChecklist = ({
  user,
  value,
  setValue,
  hasSearch,
  maxHeight,
  tabIndex,
  ...others
}: ChecklistSetOptionsProps): JSX.Element => {
  /*
  A checklist to select skills
  * user: Current user
  * value: Current checklist state
  * setValue: Sets the current state
  * hasSearch: Whether the checklist has a search box
  * maxHeight: Maximujm checklist height after which it becomes scrollable
  * tabIndex: Tab index (to allow skippable checklist)
  */

  return (
    <ChecklistWithSuggestions
      user={user}
      options={skillsOptions}
      value={value}
      setValue={setValue}
      maxSelected={value && Object.keys(value).length === maxSkills}
      hasSearch={hasSearch}
      maxHeight={maxHeight}
      tabIndex={tabIndex}
      clearButton={true}
      suggestionTag="skill_suggestion"
      suggestionLabel="Suggest skills below:"
      {...others}
    />
  )
}

export const IndustryChecklist = ({
  user,
  value,
  setValue,
  hasSearch,
  maxHeight,
  tabIndex,
  ...others
}: ChecklistSetOptionsProps): JSX.Element => {
  /*
  A checklist to select industries
  * user: Current user
  * value: Current checklist state
  * setValue: Sets the current state
  * hasSearch: Whether the checklist has a search box
  * maxHeight: Maximujm checklist height after which it becomes scrollable
  * tabIndex: Tab index (to allow skippable checklist)
  */

  return (
    <ChecklistWithSuggestions
      user={user}
      options={industriesList.map(option => {
        return { label: option, value: option }
      })}
      value={value}
      setValue={setValue}
      maxSelected={value && Object.keys(value).length === maxIndustries}
      hasSearch={hasSearch}
      maxHeight={maxHeight}
      tabIndex={tabIndex}
      clearButton={true}
      suggestionTag="industry_suggestion"
      suggestionLabel="Suggest industries below:"
      {...others}
    />
  )
}
