import * as React from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore, collection, doc, getDoc, addDoc } from "firebase/firestore"
import {
  getAuth,
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword as updatePassword_,
  updateEmail as updateEmail_,
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  sendEmailVerification,
  User as FirebaseUser,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from "firebase/auth"
import * as firebaseui from "firebaseui"
import "firebaseui/dist/firebaseui.css"
import { User } from "./model"
import { createUserData, updateUserData } from "./users"
import { baseUrl } from "./constants"
import { DateTime } from "luxon"
import { LinkedInPage } from "../components/LinkedinLogin"
import { getFunctions, httpsCallable } from "firebase/functions"
import { ErrorNotification, Spinner } from "../components/baseComponents"
import { navigate } from "gatsby"

const USERS = "users"
const verificationCodesRef = () => collection(getFirestore(app), "verificationCodes")
const connectionLogsRef = () => collection(getFirestore(app), "connectionLogs")

export const signout = (
  actionSuccess?: () => void,
  actionError?: (errorMessage: string) => void,
) => {
  signOut(getAuth(app))
    .then(() => {
      actionSuccess && actionSuccess()
    })
    .catch(error => {
      actionError && actionError(error.message)
    })
}

export const updatePassword = (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
  actionSuccess: () => void,
  actionError: (errorMessage: string) => void,
) => {
  const user = getAuth(app).currentUser
  const credential = EmailAuthProvider.credential(user.email, oldPassword)
  reauthenticateWithCredential(user, credential)
    .then(function () {
      if (oldPassword === newPassword) {
        actionError && actionError("New password cannot be the same as old password.")
      } else if (newPassword === confirmPassword) {
        updatePassword_(user, newPassword)
          .then(function () {
            actionSuccess && actionSuccess()
          })
          .catch(error => {
            actionError && actionError(error.message)
          })
      } else {
        actionError && actionError("Passwords do not match.")
      }
    })
    .catch(error => {
      actionError && actionError(error.message)
    })
}

export const updateEmail = (
  oldPassword: string,
  email: string,
  actionSuccess: () => void,
  actionError: (errorMessage: string) => void,
) => {
  const user = getAuth(app).currentUser
  const credential = EmailAuthProvider.credential(user.email, oldPassword)
  reauthenticateWithCredential(user, credential)
    .then(function () {
      updateEmail_(user, email)
        .then(() => updateUserData(user.uid, { email }))
        .then(actionSuccess)
        .catch(function (error) {
          actionError && actionError(error.message)
        })
    })
    .catch(function (error) {
      actionError && actionError(error.message)
    })
}

export const createUser = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  organisation: string,
  referralCode?: string,
  actionSuccess?: () => void,
  actionError?: (errorMessage: string) => void,
) => {
  createUserWithEmailAndPassword(getAuth(app), email, password)
    .then(async response => {
      const uid = response.user.uid
      try {
        await createUserData(uid, email, firstName, lastName, organisation, referralCode)
        actionSuccess && actionSuccess()
      } catch (e) {
        actionError && actionError(e.message)
      }
      await updateProfile(response.user, { displayName: `${firstName} | ${lastName}` })
    })
    .catch(error => {
      actionError ? actionError(error.message) : console.log(error)
    })
}

export const createFirebaseUiConfig = (
  organisation: string,
  actionSuccess: (u: User) => void,
  actionError: (errorMessage: string) => void,
): firebaseui.auth.Config => {
  return {
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: ({ user, additionalUserInfo }) => {
        if (additionalUserInfo.isNewUser) {
          createUserData(
            user.uid,
            user.email,
            user.displayName.split(" ")[0],
            user.displayName.split(" ")[1],
            organisation,
            "",
          )
            .then(actionSuccess)
            .catch(e => actionError && actionError(e.message))
        } else {
          getDoc(doc(getFirestore(app), USERS, user.uid))
            .then(docSnapshot => {
              const userData = docSnapshot.data() as User
              actionSuccess && actionSuccess(userData)
            })
            .catch(e => (actionError ? actionError(e.message) : console.log(e)))
        }
        return false
      },
    },
    // Display Google as auth providers.
    signInOptions: [GoogleAuthProvider.PROVIDER_ID],
  }
}

interface StyledFirebaseAuthProps {
  // The Firebase UI Web UI Config object.
  // See: https://github.com/firebase/firebaseui-web#configuration
  uiConfig: firebaseui.auth.Config
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?(ui: firebaseui.auth.AuthUI): void
  // The Firebase App auth instance to use.
  firebaseAuth: any // As firebaseui-web
  className?: string
}

const StyledFirebaseAuth = ({
  uiConfig,
  firebaseAuth,
  className,
  uiCallback,
}: StyledFirebaseAuthProps) => {
  const [userSignedIn, setUserSignedIn] = React.useState(false)
  const elementRef = React.useRef(null)

  React.useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth)
    if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset()

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, user => {
      if (!user && userSignedIn) firebaseUiWidget.reset()
      setUserSignedIn(!!user)
    })

    // Trigger the callback if any was set.
    if (uiCallback) uiCallback(firebaseUiWidget)

    // Render the firebaseUi Widget.
    // @ts-ignore
    firebaseUiWidget.start(elementRef.current, uiConfig)

    return () => {
      unregisterAuthObserver()
      firebaseUiWidget.reset()
    }
  }, [firebaseui, uiConfig])

  return <div className={className} ref={elementRef} />
}

interface SocialLoginProps {
  organisation: string
  actionSuccess: (u: User) => void
  actionError?: (errorMessage: string) => void
}

export const SocialLogin = ({
  organisation,
  actionSuccess,
  actionError,
}: SocialLoginProps): JSX.Element => {
  const [uiConfig, setUiConfig] = React.useState<firebaseui.auth.Config>(null)
  const [loggingIn, setLoggingIn] = React.useState(false)
  React.useEffect(() => {
    setUiConfig(createFirebaseUiConfig(organisation, actionSuccess, actionError))
  }, [organisation, actionSuccess, actionError])

  if (!uiConfig) {
    return null
  }
  return (
    <>
      <hr className="border-zinc-400 dark:border-zinc-600" />
      <>
        {!loggingIn ? (
          <>
            <div className="text-center text-zinc-700 dark:text-zinc-300 mt-4">Or</div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(app)} />
            <LinkedInPage setLoggingIn={setLoggingIn} />
          </>
        ) : (
          <div className="text-center text-zinc-700 dark:text-zinc-300 mt-4 flex gap-2 items-center">
            <Spinner size="base" />
            Getting through, please wait...
          </div>
        )}
      </>
    </>
  )
}

export const signin = (
  email: string,
  password: string,
  actionSuccess?: () => void,
  actionError?: (errorMessage: string) => void,
) => {
  signInWithEmailAndPassword(getAuth(app), email, password)
    .then(response => {
      const uid = response.user.uid
      getDoc(doc(getFirestore(app), USERS, uid))
        .then(firestoreDocument => {
          if (!firestoreDocument.exists) {
            actionError && actionError("User does not exist anymore.")
          } else {
            actionSuccess && actionSuccess()
          }
        })
        .catch(error => {
          actionError ? actionError(error.message) : console.log(error)
        })
    })
    .catch(error => {
      actionError ? actionError(error.message) : console.log(error)
    })
}

export const callLinkedinDetails = () => {
  return httpsCallable(getFunctions(app, "australia-southeast1"), "getLinkedinDetails")
}

export const signinWithToken = ({ token, user }, notify: any, organisationSlug: string) => {
  signInWithCustomToken(getAuth(app), token)
    .then(async success => {
      if (success) {
        if (user.newUser) {
          try {
            await createUserData(
              user.uid,
              user.email,
              user.firstName,
              user.lastName,
              organisationSlug,
              "",
            )
            await updateUserData(user.uid, { profilePicUrl: user.photoURL })
            await navigate(`/app/organisations/${organisationSlug}/onboarding?firstConnection=true`)
          } catch (e) {
            notify(<ErrorNotification message={e.message} />)
          }
        } else {
          navigate("/app/community")
        }
      }
    })
    .catch(error => {
      notify(<ErrorNotification message={"Failed to log in"} />)
      console.log(error)
    })
}

export const sendVerificationEmail = (
  continueUrl: string | null,
  actionSuccess: () => void,
  actionError: (e: Error) => void,
) => {
  const actionCodeSettings = {
    // After password reset, the user will be given the ability to go back
    // to this page.
    url: continueUrl || `${baseUrl}/app`,
    handleCodeInApp: false,
  }
  const user = getAuth(app).currentUser
  sendEmailVerification(user, actionCodeSettings).then(actionSuccess).catch(actionError)
}

const logConnection = (u: FirebaseUser) => {
  const now = DateTime.local()
  addDoc(connectionLogsRef(), {
    uid: u.uid,
    timestamp: now.toUTC().toISO(),
    timeOffset: now.offset,
    userAgent: navigator?.userAgent,
    url: location.pathname,
  }).catch(error => console.log(`Could not log new connection: ${error.message}`))
}

export const useAuth = (action: (u: FirebaseUser) => void) => {
  return onAuthStateChanged(getAuth(app), u => {
    action(u)
    !!u && logConnection(u)
  })
}

export const resetPassword = (email: string) => {
  const actionCodeSettings = {
    // After password reset, the user will be given the ability to go back
    // to this page.
    url: `${baseUrl}/login`,
    handleCodeInApp: false,
  }
  return sendPasswordResetEmail(getAuth(app), email, actionCodeSettings)
}

export const sendNewVerificationCode = async (email: string) => {
  const doc = await addDoc(verificationCodesRef(), {
    email,
    createdAt: Date.now(),
  })
  return doc.id
}

export const checkVerificationCode = async (ref: string, codeToCheck: string) => {
  return (await getDoc(doc(verificationCodesRef(), ref))).data().code === codeToCheck
}
