export const baseUrl = "https://app.bekko.io"
export const googlePlacesAPIKey = "AIzaSyC_A8pRQuNyPaK4jqaSz5eATqQv9iUODFw"
export const defaultOrganisation = "the-guild"
export const linkedinClientId = "86jnamb8n3x7y8"

export const oauthEndpoints = {
  google: {
    authorizeUrl: "https://accounts.google.com/o/oauth2/v2/auth",
    tokenUrl: "https://accounts.google.com/o/oauth2/token",
    clientId: "975415586210-pg4fkr2crcpnm48elk87larg84pg6c26.apps.googleusercontent.com",
  },
  microsoft: {
    authorizeUrl: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    tokenUrl: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
    clientId: "2d2c25f3-a387-45e6-8229-42f64bb8fdab",
  },
}
