import * as React from "react"
import { useLinkedIn } from "react-linkedin-login-oauth2"
// You can use provided image shipped by this package or using your own
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png"
import { linkedinClientId } from "../business_logic/constants"
import { useNotifier } from "react-headless-notifier"
import { callLinkedinDetails, signinWithToken } from "../business_logic/authentication"
import { ErrorNotification } from "./baseComponents"
import { useAtom } from "jotai"
import { organisationAtom } from "../context"

export function LinkedInPage({ setLoggingIn }) {
  const { notify } = useNotifier()
  const [organisation] = useAtom(organisationAtom)
  const { linkedInLogin } = useLinkedIn({
    clientId: linkedinClientId,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: code => {
      setLoggingIn(true)
      const getLinkedinDetails = callLinkedinDetails()

      getLinkedinDetails({ code: code, redirect: `${window.location.origin}/linkedin` })
        .then(result => {
          const data: any = result.data
          signinWithToken(data, notify, organisation.slug)
        })
        .catch(err => {
          console.log(err)
          notify(<ErrorNotification message={"Failed to log in"} />)
          setLoggingIn(false)
        })
    },

    onError: error => {
      console.log(error)
    },
    scope: "r_emailaddress r_liteprofile",
  })

  return (
    <img
      onClick={linkedInLogin}
      src={linkedin}
      alt="Sign in with Linked In"
      style={{ maxWidth: "220px", cursor: "pointer", marginLeft: "auto", marginRight: "auto" }}
    />
  )
}
