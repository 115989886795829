import * as React from "react"
import { User, Chat, Organisation, Field } from "../business_logic/model"
import defaultConfig from "../config.json"

export const renderRequiredCheck = (condition: boolean) => {
  return condition ? (
    <span className="inline-block self-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 ml-1 -mt-0.5 text-emerald-500 dark:text-emerald-400"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden={true}
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  ) : (
    <span className=" text-danger dark:text-danger-400 ml-1">*</span>
  )
}

export const renderMissingField = (condition: boolean) => {
  return (
    <div
      className={`absolute -inset-1 rounded pointer-events-none ${
        condition ? "ring-4 ring-danger dark:ring-danger-400" : ""
      }`}
    >
      <div
        className={`absolute z-0 left-0 -top-1 -translate-y-full bg-zinc-200/50 dark:bg-zinc-700/50 rounded backdrop-blur ${
          condition ? "block" : "hidden"
        }`}
      >
        Missing required field
      </div>
    </div>
  )
}

export const sortStr = (a: string, b: string) => {
  if (a > b) return -1
  if (a < b) return 1
  return 0
}

export const isTrue = (field: Field, value: any) => {
  switch (field.type) {
    case "Input":
      return !!value
    case "Textarea":
      return !!value
    case "Multi-Input":
      return !!value && value.length > 0
    case "Select":
      return !!value
    case "Radio":
      return !!value
    case "Multi-Select":
      return !!value && Array.isArray(value) && value.length > 0
    case "Checklist":
      return !!value && Array.isArray(value) && value.length > 0
    case "Checklist + Sub-options":
      return !!value && !Array.isArray(value) && Object.keys(value).length > 0
    default:
      return true
  }
}

export const countArrayMatches = (array: string[], matches: string[]): number => {
  return array.filter(
    field => !!field && matches.some(match => field.toLowerCase().includes(match.toLowerCase())),
  ).length
}

export const newMessageChat = (chat: Chat, uid: string): boolean => {
  // Determines whether a chat has a new message for a user

  if (!chat.hasMessages) {
    return false
  }
  if (!chat.lastSender || chat.lastSender === uid) {
    return false
  }
  if (!chat.lastSeen) {
    return true
  }
  return chat.lastMsgDate > chat.lastSeen[uid]
}

export const howLongAgo = (date: number | null): string => {
  if (!date) {
    return "Never"
  }
  let delta = Date.now() / 1000 - date / 1000
  if (delta < 60) {
    return "Less than 1 min ago"
  } else if (delta / 60 < 5) {
    return `${Math.round(delta / 60)}min ago`
  } else if (delta / 60 < 60) {
    return `${Math.round(delta / 300) * 5}min ago`
  } else if (delta / 3600 < 24) {
    return `${Math.round(delta / 3600)}h ago`
  } else if (delta / 3600 / 24 < 7) {
    const days = Math.round(delta / 3600 / 24)
    return `${days} day${days > 1 ? "s" : ""} ago`
  } else if (delta / 3600 / 24 / 7 < 5) {
    const weeks = Math.round(delta / 3600 / 24 / 7)
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`
  } else if (delta / 3600 / 24 / 7 < 52) {
    const months = Math.round(delta / 3600 / 24 / 30)
    return `${months} month${months > 1 ? "s" : ""} ago`
  } else {
    return "A long time ago"
  }
}

export const getQueryVariable = (variable: string, defaultValue: string) => {
  try {
    var query = window.location.search.substring(1)
  } catch (e) {
    return defaultValue
  }
  var vars = query.split("&")
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=")
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
  return defaultValue
}

export const arrayEquals = (a: Array<any>, b: Array<any>): boolean => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const getCommonOrganisations = (user1: Partial<User>, user2: Partial<User>) => {
  if (!user1.organisations || !user2.organisations) return []
  return user1.organisations.filter(o => user2.organisations.includes(o))
}

export const getConfig = (organisation: Organisation) => {
  const orgConfig = organisation?.config || {}
  return { ...defaultConfig, ...orgConfig }
}

export const lastActiveString = (lastActive: number) => {
  if (lastActive === 0) {
    return "Never"
  }
  const delta = (Date.now() - lastActive) / 1000
  if (delta < 60) {
    return "< 1 min ago"
  } else if (delta / 60 < 5) {
    return `${Math.round(delta / 60)}min ago`
  } else if (delta / 60 < 60) {
    return `${Math.round(delta / 300) * 5}min ago`
  } else if (delta / 3600 < 24) {
    return `${Math.round(delta / 3600)}h ago`
  } else if (delta / 3600 / 24 < 7) {
    const days = Math.round(delta / 3600 / 24)
    return `${days} day${days > 1 ? "s" : ""} ago`
  } else if (delta / 3600 / 24 / 7 < 5) {
    const weeks = Math.round(delta / 3600 / 24 / 7)
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`
  } else if (delta / 3600 / 24 <= 180) {
    const months = Math.round(delta / 3600 / 24 / 30)
    return `${months} month${months > 1 ? "s" : ""} ago`
  } else {
    return "More than 6 months ago"
  }
}

export const getAtPath = (obj: Object, path: string) =>
  path.split(".").reduce((o, key) => (o && typeof o[key] !== "undefined" ? o[key] : undefined), obj)

export const createAtPath = (val: any, path: string) =>
  path
    .split(".")
    .reverse()
    .reduce((o, key) => (Object.keys(o).length > 0 ? { [key]: o } : { [key]: val }), {})

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item)
}

export default function mergeDeep(target, source) {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}
