import * as React from "react"
import { Fragment, useRef } from "react"
import { Transition, Popover } from "@headlessui/react"

interface BasicPopoverProps {
  children: React.ReactNode
  trigger: React.ReactNode
  actionConfirm: () => void
  confirmButton: React.ReactNode
  actionCancel?: () => void
  cancelButton?: React.ReactNode
  btnClassName?: string
  btnDisabled?: boolean
  inline?: boolean
  confirmButtonClassName?: string
  align?: "left" | "right" | "center"
}

export const BasicPopover = ({
  children,
  trigger,
  actionConfirm,
  actionCancel,
  confirmButton,
  cancelButton,
  btnClassName,
  btnDisabled,
  inline,
  confirmButtonClassName,
  align,
}: BasicPopoverProps): JSX.Element => {
  /*
  A Popover on a button with default settings and 2 buttons: confirm and cancel
  * children: popover's content
  * trigger: trigger's content, wrapped in a button element
  * actionConfirm: action on confirm
  * actionCancel: action on cancel
  * confirmButton: contents of the confirm button
  * cancelButton: contents of the cancel button
  * btnClassName: classes of the trigger button
  * btnDisabled: whether the trigger is disabled
  * inline: whether the trigger button is inline-block or block
  * confirmButtonClassName: classes of the confirm button
  * align: where to align the popover (left, right or center)
  */

  // Keep the reference to the trigger button to artificially click it with the cancel button
  const buttonRef = useRef<HTMLButtonElement>()

  // Set the position classes based on align
  let posTranslate
  align = align || "center"
  if (!align || align === "center") {
    posTranslate = "left-1/2 -translate-x-1/2"
  } else if (align === "left") {
    posTranslate = ""
  } else if (align === "right") {
    posTranslate = "left-full -translate-x-full"
  }

  return (
    <Popover className={`relative ${inline ? "inline-block" : "block"}`}>
      {({ open }) => (
        <>
          {/* Trigger button */}
          <Popover.Button ref={buttonRef} className={btnClassName} disabled={btnDisabled}>
            {trigger}
          </Popover.Button>
          {/* Transitioned in popover contents */}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`
              absolute z-10 w-max max-w-xs
              bg-zinc-50 dark:bg-zinc-800 px-2 py-1 mt-2 shadow rounded
              border border-zinc-400
              ${posTranslate}
            `}
              style={{ maxWidth: "calc(100vw - 1rem)" }}
            >
              {/* Popover contents */}
              {children}
              {/* Action buttons beneath the contents */}
              <div className="flex justify-end items-center">
                {/* Confirm button */}
                <button
                  className={confirmButtonClassName || btnClassName}
                  onClick={e => {
                    e.preventDefault()
                    actionConfirm && actionConfirm()
                    buttonRef.current?.click()
                  }}
                >
                  {confirmButton}
                </button>
                {/* Cancel button */}
                <button
                  className="btn btn-transparent sm ml-2"
                  onClick={e => {
                    e.preventDefault()
                    actionCancel && actionCancel()
                    buttonRef.current?.click()
                  }}
                >
                  {cancelButton}
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
