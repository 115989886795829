import * as React from "react"
import ReactDOM from "react-dom"
import { useSpring } from "react-spring"

// usePrefersReducedMotion hook
const QUERY = "(prefers-reduced-motion: no-preference)"

const isRenderingOnServer = typeof window === "undefined"

const getInitialState = (): boolean => {
  // For our initial server render, we won't know if the user
  // prefers reduced motion, but it doesn't matter. This value
  // will be overwritten on the client, before any animations
  // occur.
  return isRenderingOnServer ? true : !window.matchMedia(QUERY).matches
}

export const usePrefersReducedMotion = (): boolean => {
  const [prefersReducedMotion, setPrefersReducedMotion] = React.useState(getInitialState)
  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY)
    const listener = event => {
      setPrefersReducedMotion(!event.matches)
    }
    try {
      mediaQueryList.addEventListener("change", listener)
    } catch (error) {
      //
    }
    return () => {
      try {
        mediaQueryList.removeEventListener("change", listener)
      } catch (error) {
        //
      }
    }
  }, [])
  return prefersReducedMotion
}

// useRandomInterval hook
const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min

export const useRandomInterval = (
  callback: Function,
  minDelay: number,
  maxDelay: number,
): Function => {
  const timeoutId = React.useRef(null)
  const savedCallback = React.useRef(callback)

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    let isEnabled = typeof minDelay === "number" && typeof maxDelay === "number"
    if (isEnabled) {
      const handleTick = () => {
        const nextTickAt = random(minDelay, maxDelay)
        timeoutId.current = window.setTimeout(() => {
          savedCallback.current()
          handleTick()
        }, nextTickAt)
      }
      handleTick()
    }
    return () => window.clearTimeout(timeoutId.current)
  }, [minDelay, maxDelay])

  const cancel = React.useCallback(function () {
    window.clearTimeout(timeoutId.current)
  }, [])

  return cancel
}

// useStickyState hook
export const useStickyState = (defaultValue: any, key: string) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key)
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}

// debounce
export const debounce = (callback: Function, wait: number): Function => {
  let timeoutId = null
  return (...args: any[]) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

// useBoop hook
export const useBoop = ({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 150,
  springConfig = {
    tension: 300,
    friction: 10,
  },
}): [{}, React.EventHandler<any>] => {
  const prefersReducedMotion = usePrefersReducedMotion()
  const [isBooped, setIsBooped] = React.useState(false)

  const style = useSpring({
    transform: isBooped
      ? `translate(${x}px, ${y}px)
         rotate(${rotation}deg)
         scale(${scale})`
      : `translate(0px, 0px)
         rotate(0deg)
         scale(1)`,
    config: springConfig,
  })

  React.useEffect(() => {
    if (!isBooped) {
      return
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false)
    }, timing)
    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [isBooped])

  const trigger: React.EventHandler<any> = React.useCallback((e: Event) => {
    setIsBooped(true)
  }, [])

  let appliedStyle = prefersReducedMotion ? {} : style

  return [appliedStyle, trigger]
}

// inPortal
interface inPortalProps {
  id: string
  children: React.ReactNode
}

export const InPortal = ({ id, children }: inPortalProps) => {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return ReactDOM.createPortal(children, document.querySelector(`#${id}`))
}

function useEventListener<T extends HTMLElement = HTMLDivElement>(
  eventName: keyof WindowEventMap,
  handler: (event: Event) => void,
  element?: React.RefObject<T>,
) {
  // Create a ref that stores handler
  const savedHandler = React.useRef<(event: Event) => void>()

  React.useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window
    if (!(targetElement && targetElement.addEventListener)) {
      return
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event)
      }
    }

    targetElement.addEventListener(eventName, eventListener)

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element, handler])
}

// useElementSize
export interface Size {
  width: number | undefined
  height: number | undefined
}

// Hook
export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef: React.RefObject<T>,
): Size {
  const [size, setSize] = React.useState<Size>({
    width: 0,
    height: 0,
  })

  // Prevent too many rendering using useCallback
  const updateSize = React.useCallback(() => {
    const node = elementRef?.current
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      })
    }
  }, [elementRef])

  // Initial size on mount
  React.useEffect(() => {
    updateSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener("resize", updateSize)

  return size
}

// useWindowSize

export function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
  })

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

// useOutsideClickCallback

export const useOutsideClickCallback = (ref: React.RefObject<HTMLElement>, func: Function) => {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }

    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside)
    document.addEventListener("touchend", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside)
      document.removeEventListener("touchend", handleClickOutside)
    }
  }, [ref, func])
}

// useDebounce

export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)
  React.useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => setDebouncedValue(value), delay)
    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}
