import * as React from "react"

interface NotificationProps {
  message: React.ReactNode
  title?: string
  dismiss?: () => void
}

export function SuccessNotification({ message, title, dismiss }: NotificationProps): JSX.Element {
  return (
    <div className="w-80 border-l-4 border-l-emerald-500 bg-emerald-100 dark:bg-emerald-700 dark:text-white py-4 pr-7 pl-3 text-sm flex items-center shadow-lg relative rounded-sm gap-2">
      <button
        type="button"
        className="absolute -right-1 -top-1 w-4 text-primary dark:text-primary-200 hover:text-black dark:hover:text-white m-3 transition duration-150"
        onClick={dismiss}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="w-5 h-5 text-emerald-500 dark:text-emerald-200 mr-2 flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div>
        {title && <div className="text-lg font-semibold mb-2">{title}</div>}
        <div>{message}</div>
      </div>
    </div>
  )
}

export function InfoNotification({ message, title, dismiss }: NotificationProps): JSX.Element {
  return (
    <div className="w-80 border-l-4 border-secondary bg-secondary-100 dark:bg-secondary-700 dark:text-white py-4 pr-7 pl-3 text-sm flex items-center shadow-lg relative rounded-sm gap-2">
      <button
        type="button"
        className="absolute -right-1 -top-1 w-4 text-secondary dark:text-secondary-200 hover:text-black dark:hover:text-white m-3 transition duration-150"
        onClick={dismiss}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="w-5 h-5 text-secondary dark:text-secondary-200 mr-2 flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div>
        {title && <div className="text-lg font-semibold mb-2">{title}</div>}
        <div>{message}</div>
      </div>
    </div>
  )
}

export function ErrorNotification({ message, title, dismiss }: NotificationProps): JSX.Element {
  return (
    <div className="w-80 border-l-4 border-danger bg-danger-100 dark:bg-danger-700 dark:text-white py-4 pr-7 pl-3 text-sm flex items-center shadow-lg relative rounded-sm gap-2">
      <button
        type="button"
        className="absolute -right-1 -top-1 w-4 text-danger dark:text-danger-200 hover:text-black dark:hover:text-white m-3 transition duration-150"
        onClick={dismiss}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="w-5 h-5 text-danger dark:text-danger-200 mr-2 flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div>
        {title && <div className="text-lg font-semibold mb-2">{title}</div>}
        <div>{message}</div>
      </div>
    </div>
  )
}
