import * as React from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  onSnapshot,
  getDocs,
  setDoc,
  getCountFromServer,
  deleteDoc,
  DocumentData,
  DocumentSnapshot,
} from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { User as FirebaseUser } from "firebase/auth"
import {
  ChecklistField,
  ChecklistSuboptionField,
  MultiSelectField,
  Organisation,
  RadioField,
  SelectField,
  User,
  UserFields,
  UserOrgData,
} from "./model"
import camera from "../../images/camera.svg"
import { defaultUserFields } from "../components/admin/defaultUserFields"
import { isTrue } from "../utils/utils"
import {
  fetchMembers,
  fetchMembersData,
  fetchOrganisation,
  getMemberData,
  joinOrganisation,
} from "./organisation"

const usersRef = () => collection(getFirestore(app), "users")
const PROFILE_PICTURES = "profile_pictures"
const BOOKMARKED_PROFILES = "bookmarkedProfiles"
const ORG_DATA = "orgData"

export const fetchUser = async (
  uid: string,
  removeSensitive?: boolean,
  organisationSlug?: string,
): Promise<User | Partial<User>> => {
  // Fetch the data async
  const docSnapshot = await getDoc(doc(usersRef(), uid))
  const docData = docSnapshot.data()
  // Unpack the data
  const user = { ...docData, orgData: {} } as User
  if (docData) {
    user.id = docSnapshot.id
    user.profilePicUrl = user.profilePicUrl || camera
    if (typeof user.location !== "object") {
      user.location = { matching_full_name: user.location }
    }
    if (organisationSlug) {
      const orgData = await getMemberData(uid, organisationSlug)
      user.orgData[orgData.organisation] = orgData
    }
    if (removeSensitive) {
      return Object.fromEntries(Object.entries(user).filter(([key]) => !["email"].includes(key)))
    }
    return user
  }
  return { profilePicUrl: camera } as Partial<User>
}

export const fetchUsers = async (
  uids: string[],
  removeSensitive?: boolean,
  organisationSlug?: string,
): Promise<Array<Partial<User>>> => {
  const users = await Promise.all(
    uids.map(uid => fetchUser(uid, removeSensitive, organisationSlug)),
  )
  return users
}

export const subscribeToUser = (
  firebaseUser: FirebaseUser,
  actionSuccess: (u: User) => void,
  actionError: (e: Error) => void,
) => {
  return onSnapshot(
    doc(usersRef(), firebaseUser.uid),
    async docSnapshot => {
      const userData = { ...docSnapshot.data(), orgData: {} } as User
      if (!userData) {
        return
      }
      await Promise.all(
        (userData.organisations || []).map(async org => {
          const memberData = await getMemberData(firebaseUser.uid, org)
          userData.orgData[org] = { ...memberData, userId: firebaseUser.uid, organisation: org }
        }),
      )
      const update: Partial<User> = {}
      if (
        !userData.verified &&
        (firebaseUser.emailVerified ||
          !firebaseUser.providerData.map(provider => provider.providerId).includes("password"))
      ) {
        userData.verified = true
        update.verified = true
      }
      if (!userData.creationDate) {
        update.creationDate = Number(firebaseUser.metadata.createdAt)
      }
      if (Object.keys(update).length > 0) {
        await updateUserData(firebaseUser.uid, update)
      }
      const earlybirdMaxTime = (
        await getDoc(doc(collection(getFirestore(app), "codes"), "earlybird"))
      ).data()
      userData.earlybird =
        Number(firebaseUser.metadata.createdAt) < (earlybirdMaxTime ? earlybirdMaxTime.maxTime : 0)
      userData.onFreeTrial =
        Math.max(
          Number(firebaseUser.metadata.createdAt) + 30 * 24 * 3600 * 1000,
          new Date("2021-11").getTime(),
        ) > Date.now()
      actionSuccess && actionSuccess(userData)
    },
    actionError,
  )
}

export const fetchAllUsers = async (
  openTo: string[] | null,
  organisation: string,
  options?: {
    includeEmail?: boolean
    includePending?: boolean
  },
) => {
  const orgMembers = await fetchMembers(organisation, { includePending: options?.includePending })
  const orgMembersData = await fetchMembersData(organisation, orgMembers)
  let userQuerySnapshot: DocumentSnapshot<DocumentData>[] = []
  if (orgMembers.length > 0) {
    userQuerySnapshot = await Promise.all(orgMembers.map(uid => getDoc(doc(usersRef(), uid))))
  }

  const allOrgData: { [key: string]: UserOrgData } = {}
  orgMembersData.forEach(orgData => {
    allOrgData[orgData.userId] = orgData
  })

  const users: User[] = []
  userQuerySnapshot.forEach(doc => {
    const u = doc.data() as User
    if (
      !u ||
      u.deleted ||
      (openTo &&
        (!allOrgData[doc.id] ||
          !allOrgData[doc.id].openTo ||
          openTo.every(ot => !allOrgData[doc.id].openTo.includes(ot))))
    ) {
      return
    }
    const userOrgData: UserOrgData = allOrgData[doc.id]
    users.push({
      id: doc.id,
      firstName: u.firstName,
      lastName: u.lastName,
      headline: u.headline,
      location: u.location,
      profilePicUrl: u.profilePicUrl,
      description: u.description,
      availability: u.availability,
      email: options?.includeEmail && u.email,
      creationDate: u.creationDate,
      lastActive: u.lastActive,
      orgData: userOrgData ? { [userOrgData.organisation]: userOrgData } : {},
    })
  })

  return users
}

export const fetchPendingUsers = async (organisation: string) => {
  const orgMembers = await fetchMembers(organisation, { onlyPending: true })
  let userQuerySnapshot: DocumentSnapshot<DocumentData>[] = []
  if (orgMembers.length > 0) {
    userQuerySnapshot = await Promise.all(orgMembers.map(uid => getDoc(doc(usersRef(), uid))))
  }
  const users: User[] = []
  userQuerySnapshot.forEach(doc => {
    const u = doc.data() as User
    if (!u) return
    users.push({
      id: doc.id,
      firstName: u.firstName,
      lastName: u.lastName,
      profilePicUrl: u.profilePicUrl,
      email: u.email,
    })
  })
  return users
}

export const updateUserData = async (uid: string, dataUpdate: Partial<User>) => {
  const { orgData, ...data } = dataUpdate
  try {
    await updateDoc(doc(usersRef(), uid), data)
  } catch (e) {
    if (e.code === "not-found") {
      await setDoc(doc(usersRef(), uid), data, { merge: true })
    } else {
      console.log("updateUserData error", e)
    }
  }
}

export const checkUserExists = async (email: string): Promise<boolean> => {
  const queryRef = await getCountFromServer(query(usersRef(), where("email", "==", email)))
  return queryRef.data().count > 0
}

export const createUserData = async (
  uid: string,
  email: string,
  firstName: string,
  lastName: string,
  organisationSlug: string,
  referralCode: string,
) => {
  console.log("creating user data")
  const data: Partial<User> = {
    id: uid,
    email,
    firstName,
    lastName,
    referralCode,
    verified: true,
    organisations: [organisationSlug],
  }
  await setDoc(doc(usersRef(), uid), data)
  const organisation = await fetchOrganisation(organisationSlug)
  await joinOrganisation(uid, organisation)
  return data
}

export const getBookmarkedProfiles = async (uid: string): Promise<Partial<User>[]> => {
  const collectionRef = collection(usersRef(), uid, BOOKMARKED_PROFILES)
  const collectionDocs = (await getDocs(collectionRef)).docs
  return fetchUsers(
    collectionDocs.map(doc => doc.id),
    true,
  )
}

export const bookmarkProfile = (
  uid: string,
  otherUid: string,
  bookmarked: boolean,
  actionEnd: () => void,
) => {
  const bookmarkedProfilesRef = collection(usersRef(), uid, BOOKMARKED_PROFILES)
  if (bookmarked) {
    deleteDoc(doc(bookmarkedProfilesRef, otherUid))
  } else {
    setDoc(doc(bookmarkedProfilesRef, otherUid), { bookmarkedAt: Date.now() })
  }
  actionEnd && actionEnd()
}

const countCommon = (
  user1: UserOrgData,
  user2: UserOrgData,
  field: MultiSelectField | ChecklistField | ChecklistSuboptionField,
) => {
  if (!user1 || !user2 || !user1[field.id] || !user2[field.id]) return 0
  let user1_field, user2_field
  if (Array.isArray(user1[field.id])) {
    user1_field = user1[field.id]
    user2_field = user2[field.id]
  } else {
    user1_field = Object.keys(user1[field.id])
    user2_field = Object.keys(user2[field.id])
  }
  const score = user1_field.filter(f => user2_field.includes(f)).length / (field.maxSelected || 1)
  return score
}

const countDifferent = (
  user1: UserOrgData,
  user2: UserOrgData,
  field: MultiSelectField | ChecklistField | ChecklistSuboptionField,
) => {
  if (!user1 || !user2 || !user1[field.id] || !user2[field.id]) return 0
  let user1_field, user2_field
  if (Array.isArray(user1[field.id])) {
    user1_field = user1[field.id]
    user2_field = user2[field.id]
  } else {
    user1_field = Object.keys(user1[field.id])
    user2_field = Object.keys(user2[field.id])
  }
  const score =
    (user1_field.filter(f => !user2_field.includes(f)).length +
      user2_field.filter(f => !user1_field.includes(f)).length) /
    (field.maxSelected || 1) /
    2
  return score
}

const measureCloseness = (
  user1: UserOrgData,
  user2: UserOrgData,
  field: SelectField | RadioField,
) => {
  if (!user1 || !user2 || !user1[field.id] || !user2[field.id]) return 0
  const user1_field = user1[field.id]
  const user2_field = user2[field.id]
  if (!field.options.includes(user1_field) || !field.options.includes(user2_field)) return 0
  if (field.distanceMeasurement == "Linear") {
    const base = field.options.length - 1
    return (
      (base - Math.abs(field.options.indexOf(user1_field) - field.options.indexOf(user2_field))) /
      base
    )
  }
  const base = (field.options.length - 1) ** 2
  return (
    (base - (field.options.indexOf(user1_field) - field.options.indexOf(user2_field)) ** 2) / base
  )
}

const mainScore = (user1: UserOrgData, user2: UserOrgData, userFields: UserFields): number => {
  if (!user1 || !user2) return 0
  type MatchingFields =
    | MultiSelectField
    | ChecklistField
    | ChecklistSuboptionField
    | SelectField
    | RadioField
  const matchingFields: Array<MatchingFields> = userFields.fields
    .filter((field: MatchingFields) => field.usedInMatching)
    .map(field => field as MatchingFields)
  return matchingFields
    .map(field => {
      if (field.matchingType === "Most common") {
        return countCommon(user1, user2, field) * field.matchingWeight
      }
      if (field.matchingType === "Most different") {
        return countDifferent(user1, user2, field) * field.matchingWeight
      }
      if (field.matchingType === "Closest") {
        return measureCloseness(user1, user2, field) * field.matchingWeight
      }
      return 0
    })
    .reduce((p, n) => p + n, 0)
}

const secondaryScore = (user: User, organisationSlug: string): number => {
  return (
    Object.values(user).filter(v => !!v).length +
    Object.values(user.orgData[organisationSlug] || {}).filter(v => !!v).length
  )
}

export const prioritiseUsers = (user: User, allUserData: User[], organisation: Organisation) => {
  const filteredUsers = allUserData.filter(
    u =>
      !user ||
      u.id === user.id ||
      hasAllRequiredFields(u, organisation.userFields || defaultUserFields, organisation.slug),
  )
  if (!user) return filteredUsers
  const scores = Object.fromEntries(
    filteredUsers.map(u => [
      u.id,
      mainScore(
        user.orgData[organisation.slug],
        u.orgData[organisation.slug],
        organisation.userFields || defaultUserFields,
      ) *
        1000 +
        secondaryScore(u, organisation.slug),
    ]),
  )
  return filteredUsers.sort((u1, u2) => (scores[u2.id] || 0) - (scores[u1.id] || 0))
}

export const setProfilePic = async (uid: string, image: Blob) => {
  const imageRef = ref(getStorage(app), `${PROFILE_PICTURES}/${uid}`)
  await uploadBytes(imageRef, image)
  const url = await getDownloadURL(imageRef)
  await updateUserData(uid, { profilePicUrl: url })
  return url
}

const concatParts = parts => {
  let arr = []
  const filteredParts = parts.filter(p => p)
  filteredParts.forEach((p, i) => {
    arr.push(p)
    if (i < filteredParts.length - 1) {
      if (i < filteredParts.length - 2) {
        arr.push(", ")
      } else {
        arr.push(" and ")
      }
    }
  })
  return arr
}

export const getSimilarities = (
  user1OrgData: Partial<UserOrgData>,
  user2OrgData: Partial<UserOrgData>,
  user2FirstName: string,
  userFields: UserFields,
) => {
  const similarityParts = userFields.similarityFields
    .map(similarity => {
      const field = userFields.fields.find(field => field.id === similarity.fieldId)
      if (!field) return null

      let commonValues: any[]
      if (field.type === "Checklist" || field.type === "Multi-Select") {
        commonValues =
          user1OrgData && user2OrgData && user1OrgData[field.id] && user2OrgData[field.id]
            ? user1OrgData[field.id].filter(value => user2OrgData[field.id].includes(value))
            : []
      } else if (field.type === "Checklist + Sub-options") {
        commonValues =
          user1OrgData &&
          user2OrgData &&
          user1OrgData[field.id] &&
          user1OrgData[field.id] &&
          user2OrgData[field.id]
            ? Object.keys(user1OrgData[field.id]).filter(value =>
                Object.keys(user2OrgData[field.id]).includes(value),
              )
            : []
      }

      if (commonValues.length === 0) return null
      return (
        <span>
          {similarity.prefix ? `${similarity.prefix} ` : ""}
          {concatParts(
            commonValues.map(x => (
              <span key={x} className="font-medium">
                {x}
              </span>
            )),
          )}
          {similarity.suffix ? ` ${similarity.suffix}` : ""}
        </span>
      )
    })
    .filter(part => !!part)

  if (similarityParts.length === 0) return null

  return (
    <div>
      {user2FirstName} and you both {concatParts(similarityParts)}.
    </div>
  )
}

export const hasAllRequiredFields = (
  user: User,
  userFields: UserFields,
  organisation: string,
): boolean => {
  const includedPlatformFields =
    userFields?.platformFields.filter(pf => pf.active).map(pf => pf.value) || []
  if (!user.orgData) {
    return false
  }
  const orgData = user.orgData[organisation]
  const items = {
    headline: !includedPlatformFields.includes("headline") || !!user.headline,
    profilePicUrl: !!user.profilePicUrl,
    location: !includedPlatformFields.includes("location") || !!user.location?.matching_full_name,
    description: !includedPlatformFields.includes("description") || !!user.description,
    availability:
      !includedPlatformFields.includes("availability") ||
      (!!user.availability && user.availability.length > 0),
    ...Object.fromEntries(
      (userFields || defaultUserFields).fields
        .filter(field => field.mandatory)
        .map(field => [field.id, orgData && isTrue(field, orgData[field.id])]),
    ),
  }
  return Object.values(items).every(v => !!v)
}
