import * as React from "react"
import { Tab } from "@headlessui/react"

interface TabsProps {
  tabOptions: {
    id: string
    header: React.ReactNode
    content: React.ReactNode
  }[]
  noContentWrapper?: boolean
  selectedIndex?: number
  onChange?: (index: number) => void
  vertical?: boolean
  manual?: boolean
  defaultIndex?: number
  as?: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export const Tabs = ({ tabOptions, noContentWrapper, ...others }: TabsProps) => {
  return (
    <Tab.Group {...others}>
      <Tab.List className="flex space-x-1 border-b border-zinc-200 dark:border-zinc-700">
        {tabOptions.map(to => (
          <Tab
            key={to.id}
            className={({ selected }) =>
              classNames(
                "w-full py-2.5 text-sm lg:text-base leading-5 font-medium transition duration-150",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary-400 ring-white ring-opacity-60 border-b-2 rounded-t",
                "hover:bg-zinc-500/20 hover:text-black dark:hover:text-white -mb-px",
                selected
                  ? "text-black dark:text-white border-primary-500"
                  : "text-zinc-600 dark:text-zinc-400 border-transparent",
              )
            }
          >
            {to.header}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-4">
        {tabOptions.map(to => (
          <Tab.Panel
            key={to.id}
            className={
              noContentWrapper ? "pt-2" : classNames("bg-white dark:bg-primary-100/10 rounded p-3")
            }
          >
            {to.content}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}
