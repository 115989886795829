import * as React from "react"
import { Fragment, useState } from "react"
import { Combobox, Transition } from "@headlessui/react"
import { useDebounce } from "../../utils/hooks"
import { Option } from "../../business_logic/model"

interface BasicComboboxProps {
  getOptions: (query?: string) => Option[] | Promise<Option[]>
  value: Option
  onChange: (option: Option) => void
  renderOption?: (option: Option, selected?: boolean, active?: boolean) => React.ReactNode
  renderSelected?: (option: Option) => string
  placeholder?: string
  noOptionsMessage?: string
  notFoundMessage?: string
  loadingMessage?: string
  debounceTime?: number
  clearable?: boolean
  className?: string
}

export const BasicCombobox = ({
  getOptions,
  value,
  onChange,
  renderOption,
  renderSelected,
  placeholder = "Search...",
  noOptionsMessage = "No options.",
  notFoundMessage = "No matches found.",
  loadingMessage = "Loading...",
  debounceTime = 300,
  clearable = false,
  className = "",
}: BasicComboboxProps): JSX.Element => {
  const [query, setQuery] = useState(value?.label || "")
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Option[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const debouncedQuery = useDebounce(query, debounceTime)

  React.useEffect(() => {
    const updateOptions = async () => {
      setLoading(true)
      const newOptions = await getOptions(debouncedQuery)
      setOptions(newOptions)
      setLoading(false)
    }
    if (isOpen) {
      updateOptions()
    }
  }, [debouncedQuery, getOptions, isOpen])

  const _renderSelected = React.useCallback(
    (option: Option | null) => {
      if (!option) {
        return ""
      }
      if (renderSelected) {
        return renderSelected(option)
      }
      return option.label
    },
    [renderSelected],
  )

  const _renderOption = React.useCallback(
    (option: Option, selected?: boolean, active?: boolean) => {
      if (renderOption) {
        return renderOption(option, selected, active)
      }
      return (
        <span className={`block truncate sm:text-sm ${selected ? "font-medium" : "font-normal"}`}>
          {option.label}
        </span>
      )
    },
    [renderOption],
  )

  return (
    <div className={`relative ${className}`}>
      <Combobox value={value} onChange={onChange} nullable={clearable}>
        {({ open }) => {
          React.useEffect(() => {
            setIsOpen(open)
          }, [open])
          return (
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden">
                <Combobox.Input
                  placeholder={placeholder}
                  className={`input sm:text-sm pr-8 ${
                    value ? "" : "text-zinc-700 dark:text-zinc-400"
                  }`}
                  displayValue={(option: Option | null) => _renderSelected(option)}
                  onChange={event => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {clearable ? (
                    <span
                      onClick={() => {
                        setQuery("")
                        onChange(null)
                      }}
                      className="text-zinc-700 dark:text-zinc-400 hover:text-black dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                      </svg>
                    </span>
                  ) : (
                    <span className="text-zinc-700 dark:text-zinc-400 hover:text-black dark:hover:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-50 dark:bg-zinc-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm thin-scrollbar">
                  {options.length === 0 ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-zinc-700 dark:text-zinc-400">
                      {loading ? loadingMessage : query ? notFoundMessage : noOptionsMessage}
                    </div>
                  ) : (
                    options.map(option => (
                      <Combobox.Option
                        key={option.value}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pr-10 pl-4 ${
                            active ? "bg-secondary-500/20" : ""
                          }`
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            {_renderOption(option, selected, active)}
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 right-0 flex items-center pr-3 text-secondary-500`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          )
        }}
      </Combobox>
    </div>
  )
}
