import * as React from "react"
import * as ReactDOM from "react-dom"
import { useFloating, offset, arrow, flip } from "@floating-ui/react-dom-interactions"
import { useWindowSize } from "../../utils/hooks"

interface TooltipProps {
  children: React.ReactElement
  tooltipContents: React.ReactNode
  needsUpdate: any
}

const Tooltip = ({ children, tooltipContents, needsUpdate }: TooltipProps) => {
  const portalRoot = React.useRef<HTMLDivElement>(document.createElement("div"))
  portalRoot.current.style.zIndex = "100001"
  portalRoot.current.style.position = "fixed"
  const arrowRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const body = document.body
    body.appendChild(portalRoot.current)
    return () => {
      body.removeChild(portalRoot.current)
    }
  })

  const open = !!tooltipContents
  const { x, y, reference, floating, strategy, update, middlewareData, placement } = useFloating({
    open,
    placement: "top-end",
    strategy: "fixed",
    middleware: [offset(8), arrow({ element: arrowRef }), flip()],
  })

  const windowSize = useWindowSize()
  React.useEffect(update, [needsUpdate, windowSize, tooltipContents])

  return (
    <>
      {React.cloneElement(children, {
        ref: reference,
      })}
      {open &&
        ReactDOM.createPortal(
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className="rounded shadow bg-zinc-100 dark:bg-zinc-900 px-1 py-1 text-black dark:text-white text-xs flex items-center gap-1 pointer-events-none max-w-[250px] border border-danger/50"
          >
            {tooltipContents}
            <div
              ref={arrowRef}
              className="border-8 border-b-0 border-t-danger-300 dark:border-t-danger-700 border-l-transparent border-r-transparent"
              style={{
                position: "absolute",
                bottom: -8,
                right:
                  middlewareData.flip && Object.keys(middlewareData.flip).length > 0 ? null : 4,
                left: middlewareData.flip && Object.keys(middlewareData.flip).length > 0 ? 4 : null,
              }}
            />
          </div>,
          portalRoot.current,
        )}
    </>
  )
}

type Data = Array<{ [key: string]: string }>

interface MultiInputProps extends React.HTMLAttributes<HTMLDivElement> {
  columns: Array<{
    label: React.ReactNode
    value: string
    type?: string
    validation?: (s: string) => string | null | Promise<string>
  }>
  showHeader: boolean
  data: Data
  setData: (d: Data) => void
  autoFocus?: boolean
  syncErrors?: (errors: Data) => void
  columnTitleClasses?: string
  inputSize?: "xs" | "sm" | "md" | "lg" | "xl"
  nDefaultBlankRows?: number
  rowName?: string
}

export const MultiInput = ({
  columns,
  showHeader,
  data,
  setData,
  autoFocus,
  syncErrors,
  columnTitleClasses,
  inputSize = "md",
  nDefaultBlankRows = 3,
  rowName = "row",
  ...rest
}: MultiInputProps): JSX.Element => {
  /*
  Multiple inputs with add-able rows based on a given schema
  * columns: Name of the different inputs for each row
  * showHeader: whether to show the column names
  * data: current data held in the multiinput
  * setData: Sets the data
  */

  // An empty row of the given schema
  const emptyRow = columns.reduce((a, b) => {
    a[b.value] = ""
    return a
  }, {})
  // Ensure there is at least an empty row to start with
  const data_ =
    data && data.length > 0
      ? data.map(d =>
          columns.reduce((a, b) => {
            a[b.value] = d[b.value] || ""
            return a
          }, {}),
        )
      : Array(nDefaultBlankRows)
          .fill(0)
          .map(i => ({ ...emptyRow }))
  const nCols = columns.length
  const inputsWrapperRef = React.useRef<HTMLDivElement>(null)
  const nChanges = React.useRef<number>(0)
  const [errors, setErrors] = React.useState<{ [key: string]: string }[]>(
    data_.map(d => Object.fromEntries(Object.keys(d).map(k => [k, ""]))),
  )

  React.useEffect(() => {
    if (nChanges.current > 0 && inputsWrapperRef.current) {
      const row = Array.from(inputsWrapperRef.current.children).slice(-1)[0] as HTMLDivElement
      const textbox = row.children[0] as HTMLInputElement
      textbox.focus()
    }
  }, [data_.length])

  const setErrorsAll = (e: Data, sync?: boolean) => {
    setErrors(e)
    syncErrors && sync && syncErrors(e)
  }

  return (
    <div {...rest}>
      {/* Columns names if required */}
      {showHeader && (
        <div
          className={`grid gap-x-1.5 mb-1 pr-3`}
          style={{ gridTemplateColumns: Array(nCols).fill("1fr").join(" ") }}
        >
          {columns.map(col => (
            <label className={`label ${columnTitleClasses}`} key={col.value}>
              {col.label}
            </label>
          ))}
        </div>
      )}
      <div className="space-y-1.5 pr-3 isolate" ref={inputsWrapperRef}>
        {/* Map the currently held data to rows of inputs */}
        {data_.map((row, i) => {
          return (
            <div key={i} className={`flex group relative transition duration-150`}>
              {/* Map the row's data to inputs */}
              {Object.entries(row).map(([field, cell]: [string, string], j) => {
                const errorMessage = errors[i][columns[j].value]
                return (
                  <Tooltip
                    tooltipContents={errorMessage}
                    key={`${field}-${i}`}
                    needsUpdate={data_.length}
                  >
                    <input
                      className={`input peer min-w-[0] focus:min-w-[250px] duration-100 transition-all relative z-10 group-focus-within:shadow-lg ${
                        j > 0 ? "ml-1.5" : ""
                      }${errorMessage ? " border-danger ring-2 ring-danger/50" : ""} ${inputSize}`}
                      type={columns[j].type || "text"}
                      value={cell}
                      onChange={e => {
                        const newData = [...data_]
                        newData[i] = { ...newData[i], [field]: e.target.value }
                        const newErrors = [...errors]
                        newErrors[i] = { ...newErrors[i], [field]: "" }
                        setData(newData)
                        setErrors(newErrors)
                        const newErrors2 = [...newErrors]
                        newErrors2[i] = { ...newErrors2[i], [field]: "not validated" }
                        syncErrors && syncErrors(newErrors2)
                      }}
                      onBlur={async () => {
                        if (columns[j].validation) {
                          const newErrors = [...errors]
                          newErrors[i] = {
                            ...newErrors[i],
                            [field]: await columns[j].validation(cell),
                          }
                          setErrorsAll(newErrors, true)
                        }
                      }}
                      autoFocus={autoFocus && i === data_.length - 1 && j === 0}
                      autoComplete="never"
                    />
                  </Tooltip>
                )
              })}
              {/* Remove row button */}
              <button
                className={`h-full w-4 flex items-center justify-start absolute -right-2.5 top-0 pl-px pt-0.5 rounded-tr-lg rounded-br-lg z-10
                bg-primary-300 hover:bg-primary focus:bg-primary dark:bg-primary-700 dark:hover:bg-primary-400 dark:focus:bg-primary-400 text-white
                  opacity-0 group-hover:opacity-100 focus:opacity-100 hover:opacity-100 !ml-0 peer-focus:opacity-100`}
                onClick={e => {
                  nChanges.current++
                  // Filter out the row of the given index
                  const newData = data_.filter((row, j) => i !== j)
                  const newErrors = errors.filter((row, j) => i !== j)
                  // Set the current data to the filtered one, ensuring at least an empty row
                  if (newData.length > 0) {
                    setData(newData)
                    setErrors(newErrors)
                  } else {
                    setData([{ ...emptyRow }])
                    setErrors([{ ...emptyRow }])
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          )
        })}
      </div>
      {/* Add row button */}
      <div className="mt-3 pr-3 sticky bottom-0 z-10">
        <button
          className={`rounded-full h-8 pl-2 pr-3 flex gap-1 items-center justify-center mx-auto relative z-20 text-sm
          bg-primary hover:bg-primary-650 dark:bg-primary-400 dark:hover:bg-primary text-white`}
          onClick={() => {
            setData([...data_, { ...emptyRow }])
            setErrors([...errors, { ...emptyRow }])
            nChanges.current++
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Add {rowName}
        </button>
      </div>
    </div>
  )
}
