import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  DocumentReference,
  updateDoc,
  query,
  where,
  onSnapshot,
  DocumentData,
  getDocs,
  deleteDoc,
  deleteField,
} from "firebase/firestore"
import { User, Chat, Message } from "./model"
import { fetchUser } from "./users"
import { sortStr, arrayEquals } from "../utils/utils"
import camera from "../../images/camera.svg"
import { Dispatch } from "react"

const chatsRef = () => collection(getFirestore(app), "chats")
const tasksRef = () => collection(getFirestore(app), "tasks")
const MESSAGES = "messages"

// Delay before notifying by email of a new message in the chat
const emailNotificationDelay = 60 * 1000 * 5

export const fetchChatOtherUser = async (user: User, chat: Chat): Promise<Partial<User>> => {
  // Get the id of the other user
  const otherId = chat.members.filter(id => id !== user.id)[0]
  // Fetch the data async
  const u = await fetchUser(otherId, true)
  const otherUser = u
    ? {
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        profilePicUrl: u.profilePicUrl,
      }
    : ({ profilePicUrl: camera } as Partial<User>)
  return otherUser
}

export const fetchChat = async (uid1: string, uid2: string) => {
  const snap = await getDocs(
    query(
      chatsRef(),
      where(
        "members",
        "==",
        [uid1, uid2].sort((a, b) => sortStr(b, a)),
      ),
    ),
  )
  if (snap.docs.length === 0) return null
  const chats: Chat[] = []
  snap.forEach(doc => chats.push({ ...doc.data(), id: doc.id } as Chat))
  return chats[0]
}

export const fetchChats = (
  user: User,
  actionSuccess: (chats: Chat[]) => void,
  actionError: (e: Error) => void,
) => {
  return onSnapshot(
    query(chatsRef(), where("members", "array-contains", user.id)),
    querySnapshot => {
      const newChats = []
      querySnapshot.forEach(doc => {
        const chat = doc.data() as Chat
        chat.id = doc.id
        chat.name = chat.membersNames.filter(name => name !== user.firstName).join(", ")
        newChats.push(chat)
      })
      const newChatsSorted = newChats.sort((a, b) => b.lastMsgDate - a.lastMsgDate)
      actionSuccess && actionSuccess(newChatsSorted)
    },
    actionError,
  )
}

export const fetchChatMessages = (
  chat: Chat,
  fetchAction: (messages: Message[]) => void,
  actionError?: (errorMessage: string) => void,
) => {
  return onSnapshot(
    collection(getFirestore(app), chatsRef().path, chat.id, MESSAGES),
    querySnapshot => {
      const messages = []
      querySnapshot.forEach(doc => {
        const message = doc.data() as Message
        message.id = doc.id
        messages.push(message)
      })
      fetchAction(messages)
    },
    error => {
      actionError ? actionError(error.message) : console.log(error)
    },
  )
}

export const markMessagesAsRead = (chat: Chat, user: User) => {
  const date = Date.now()
  const update = {}
  if (
    chat.lastSender !== user.id &&
    chat.newMessageTask &&
    date <= chat.lastMsgDate + emailNotificationDelay
  ) {
    deleteDoc(doc(tasksRef(), chat.newMessageTask))
    update["newMessageTask"] = deleteField()
  }
  update["lastSeen"] = chat.lastSeen || {}
  update["lastSeen"][user.id] = date
  return updateDoc(doc(chatsRef(), chat.id), update)
}

export const sendMessage = async (
  user: User,
  chat: Chat,
  newMessage: string,
  organisationSlug: string,
  actionEnd?: Function,
  noNotification?: boolean,
) => {
  if (newMessage && newMessage.trim().length > 0) {
    const date = Date.now()
    const message = {
      text: newMessage.trim(),
      author: user.id,
      msgDate: date,
    }

    // Define a task to send a new message email
    const newMsgTask = {
      performAt: date + emailNotificationDelay,
      status: "scheduled",
      worker: "newMessage",
      options: {
        sender: user.id,
        receiver: chat.members.filter(m => m !== user.id)[0],
        chatId: chat.id,
        organisation: organisationSlug,
      },
    }

    // Define the update to the chat document with the link to a new message email task
    const chatUpdate = (docRef: DocumentReference<DocumentData>) => {
      const update = {
        lastMsgDate: date,
        lastSender: user.id,
        hasMessages: true,
      }
      if (docRef && docRef.id) {
        update["newMessageTask"] = docRef.id
      }
      return update
    }

    // If the last message sender is not the current user, create a new message email task
    // and update the chat document
    if (chat.lastSender !== user.id) {
      let docRef: DocumentReference<DocumentData> = null
      if (!noNotification) {
        docRef = await addDoc(tasksRef(), newMsgTask)
      }
      await updateDoc(doc(chatsRef(), chat.id), chatUpdate(docRef))
    } else {
      // If the last message sender is the current user and the other member has seen the previous message,
      // create a new message email task and update the chat document
      if (chat.lastSeen[chat.members.filter(m => m !== user.id)[0]] > chat.lastMsgDate) {
        let docRef: DocumentReference<DocumentData> = null
        if (!noNotification) {
          docRef = await addDoc(tasksRef(), newMsgTask)
        }
        await updateDoc(doc(chatsRef(), chat.id), chatUpdate(docRef))
        // If the last message sender is the current user and the other member has not seen the previous message,
        // **do not** create a new message email task
      } else {
        await updateDoc(doc(chatsRef(), chat.id), chatUpdate(null))
      }
    }

    // Add the message to the collection
    await addDoc(collection(getFirestore(app), chatsRef().path, chat.id, MESSAGES), message)

    actionEnd && actionEnd()
  }
}

export const groupMessages = (messages: Message[]): Message[][] => {
  // Group messagesby the same author written within 5mn of each other
  return messages.reduce((state, x) => {
    if (state.length === 0) {
      return [[x]]
    }
    const lastMsg = state.slice(-1)[0].slice(-1)[0]
    if (lastMsg.author === x.author && Math.abs(lastMsg.msgDate - x.msgDate) <= 5 * 60 * 1000) {
      return [...state.slice(0, -1), [...state.slice(-1)[0], x]]
    }
    return [...state, [x]]
  }, [])
}

export const chatWithUser = (
  currentUser: Partial<User>,
  user: Partial<User>,
  chats: Chat[],
  openChats: Chat[],
  manageChats: Dispatch<Chat[]>,
) => {
  const sortedUsers = [currentUser, user].sort((a, b) => sortStr(b.id, a.id))
  const chat = chats.filter(c =>
    arrayEquals(
      c.members,
      sortedUsers.map(u => u.id),
    ),
  )[0]
  if (chat) {
    if (openChats.filter(openChat => openChat.id === chat.id).length === 0) {
      manageChats([...openChats, chat])
    }
  } else {
    const newChat = {
      members: sortedUsers.map(u => u.id),
      membersNames: sortedUsers.map(u => u.firstName),
      lastMsgDate: null,
      lastSender: null,
      newMessageTask: null,
      lastSeen: null,
      hasMessages: false,
    }
    addDoc(chatsRef(), newChat).then(chat => {
      if (openChats.filter(openChat => openChat.id === chat.id).length === 0) {
        const chatName = newChat.membersNames
          .filter(name => name !== currentUser.firstName)
          .join(", ")
        manageChats([...openChats, { ...newChat, id: chat.id, name: chatName }])
      }
    })
  }
}
