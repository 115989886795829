import * as React from "react"
import { City } from "../../business_logic/model"
import { googlePlacesAPIKey } from "../../business_logic/constants"
import { BasicCombobox } from "./basicCombobox"

let autoComplete

const loadScript = (url: string, callback: () => void, id?: string) => {
  if (id) {
    const element = document.getElementById(id)
    if (element) {
      callback()
      return
    }
  }
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.onload = callback
  script.src = url
  if (id) {
    script.id = id
  }
  document.getElementsByTagName("head")[0].appendChild(script)
}

interface CityDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  value: City
  setValue: React.Dispatch<City>
}

export const CityDropdown = ({ value, setValue, ...others }: CityDropdownProps): JSX.Element => {
  /*
  Dropdown looking for cities based on the Google places API
  * value: currently used city value
  * setValue: sets the value
  */
  const [loadingAPI, setLoadingAPI] = React.useState(true)
  const option = { value: value.place_id, label: value.matching_full_name }
  const onChange = option => {
    const cityValue = { matching_full_name: option.label, place_id: option.value }
    setValue(cityValue)
  }

  React.useEffect(() => {
    if (!window.google?.maps) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${googlePlacesAPIKey}&libraries=places`,
        () => {
          autoComplete = new window.google.maps.places.AutocompleteService()
          setLoadingAPI(false)
        },
        "gmaps-autocomplete-script",
      )
    }
  }, [])

  const getOptions = async (query: string) => {
    if (!query || loadingAPI) {
      return []
    }
    if (!autoComplete) {
      autoComplete = new window.google.maps.places.AutocompleteService()
    }
    const response = await autoComplete.getPlacePredictions({ input: query, types: ["(cities)"] })
    return response.predictions.map(c => ({ value: c.place_id, label: c.description }))
  }

  return (
    <BasicCombobox
      getOptions={getOptions}
      value={option}
      onChange={onChange}
      noOptionsMessage="Search a place"
      {...others}
    />
  )
}
