import * as React from "react"

interface SpinnerProps {
  size?: "sm" | "base" | "lg" | "xl" | "2xl"
}

export const Spinner = ({ size }: SpinnerProps): JSX.Element => {
  /**
  A simple spinner with variable size
  * size is the size of the spinner (sm to 2xl)
  */

  // Define the size
  let sizeClass
  switch (size) {
    case "sm":
      sizeClass = "h-4 w-4"
      break
    case "base":
      sizeClass = "h-5 w-5"
      break
    case "lg":
      sizeClass = "h-6 w-6"
      break
    case "xl":
      sizeClass = "h-8 w-8"
      break
    case "2xl":
      sizeClass = "h-10 w-10"
      break
    default:
      sizeClass = "h-5 w-5"
      break
  }

  // Return an animated svg
  return (
    <svg className={`animate-spin ${sizeClass} ml-2 inline-block`} viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  )
}
