import * as React from "react"
import { atom, useAtom } from "jotai"
import { User, UserSubscription, Organisation, Chat } from "../business_logic/model"
import { useAuth } from "../business_logic/authentication"
import { subscribeToUser, updateUserData } from "../business_logic/users"
import { getUserSubscription } from "../business_logic/subscriptions"
import {
  fetchMember,
  subscribeToMemberData,
  subscribeToOrganisations,
} from "../business_logic/organisation"
import { initFirebase } from "../business_logic/firebase"
import { fetchChats } from "../business_logic/messaging"
import { defaultOrganisation } from "../business_logic/constants"

const analytics = initFirebase()
const isBrowser = typeof window !== "undefined"

const userValueAtom = atom<User>(null)
export const userAtom = atom<User>(get => {
  const localStorageUser = isBrowser && localStorage.getItem("inspiring-chats-user")
  return get(userValueAtom) || (localStorageUser ? JSON.parse(localStorageUser) : null)
})
const writeableUserAtom = atom<User, User>(
  get => get(userAtom),
  (get, set, newUser) => {
    set(userValueAtom, newUser)
    const { email, ...noSensitiveDataUser } = newUser
    isBrowser && localStorage.setItem("inspiring-chats-user", JSON.stringify(noSensitiveDataUser))
  },
)
const updateableUserAtom = atom<User, (u: User) => User>(
  get => get(userAtom),
  (get, set, newUserFunc) => {
    const newUser = newUserFunc(get(userValueAtom))
    set(userValueAtom, newUser)
    const { email, ...noSensitiveDataUser } = newUser
    isBrowser && localStorage.setItem("inspiring-chats-user", JSON.stringify(noSensitiveDataUser))
  },
)
export const subscriptionAtom = atom<UserSubscription>(null)
export const mainLoadingAtom = atom<boolean>(true)

export const orgsLoadingAtom = atom<boolean>(false)
const organisationsValueAtom = atom<Organisation[]>([])
export const organisationsAtom = atom<Organisation[], Organisation[]>(
  get => get(organisationsValueAtom),
  (get, set, val) => {
    set(organisationsValueAtom, val)
    set(orgsLoadingAtom, false)
  },
)

export const organisationSlugValueAtom = atom<string>(null)
export const organisationSlugAtom = atom<string, string>(
  get =>
    get(organisationSlugValueAtom) ||
    (get(userAtom)?.organisations && get(userAtom).organisations.length > 0
      ? get(userAtom)?.organisations[0]
      : defaultOrganisation),
  (get, set, val) => {
    set(organisationSlugValueAtom, val)
  },
)

export const chatsAtom = atom<Chat[]>([])
export const chatsWithMessagesAtom = atom<Chat[]>(get => get(chatsAtom).filter(c => c.hasMessages))
export const redirectUrlValueAtom = atom<string>(null)
export const redirectUrlAtom = atom<string, string>(
  get => get(redirectUrlValueAtom),
  (get, set, val) => {
    set(redirectUrlValueAtom, val)
  },
)
export const bookingTabAtom = atom(0)
export const organisationAtom = atom<Organisation>(get => {
  const orgSlug = get(organisationSlugAtom)
  return get(organisationsAtom).find(org => org.slug === orgSlug) || { slug: orgSlug }
})
export const userOrgStatusAtom = atom<"pending" | "active" | "rejected" | null>(null)

export const DataSubscriptions = ({}) => {
  const [user, setUser] = useAtom(writeableUserAtom)
  const [, updateUser] = useAtom(updateableUserAtom)
  const [, setMainLoading] = useAtom(mainLoadingAtom)
  const [, setSubscriptionData] = useAtom(subscriptionAtom)
  const [, setOrganisations] = useAtom(organisationsAtom)
  const [, setChats] = useAtom(chatsAtom)
  const [, setOrgsLoading] = useAtom(orgsLoadingAtom)
  const [organisationSlug, setOrganisationSlug] = useAtom(organisationSlugAtom)
  const [userOrgStatus, setUserOrgStatus] = useAtom(userOrgStatusAtom)

  React.useEffect(() => {
    return useAuth(async firebaseUser => {
      if (firebaseUser) {
        setOrgsLoading(true)
        try {
          await updateUserData(firebaseUser.uid, {
            lastActive: Date.now(),
          })
        } catch (e) {
          // pass - when a user first creates their account the document may not be created yet at this point
        }
        const unsubscribe = subscribeToUser(firebaseUser, setUser, console.log)
        setMainLoading(false)
        return unsubscribe
      } else {
        localStorage.removeItem("inspiring-chats-user")
      }
    })
  }, [])

  React.useEffect(() => {
    if (user) {
      return getUserSubscription(
        user.id,
        subscription => {
          setSubscriptionData(subscription)
          const userData = { ...user }
          userData.hasSubscription = true
          setUser(userData)
        },
        () => {
          const userData = { ...user }
          userData.hasSubscription = false
          setUser(userData)
        },
      )
    }
  }, [user?.id])

  React.useEffect(() => {
    if (user) {
      return fetchChats(user, setChats, console.log)
    }
  }, [user?.id])

  React.useEffect(() => {
    const allOrgs = Array.from(new Set((user?.organisations || []).concat([organisationSlug])))
    return subscribeToOrganisations(allOrgs, setOrganisations, console.log)
  }, [organisationSlug, user?.organisations])

  React.useEffect(() => {
    if (
      user?.organisations &&
      !user.organisations?.includes(organisationSlug) &&
      window.location.pathname.startsWith("/app")
    ) {
      setOrganisationSlug(user.organisations[0])
    }
    return
  }, [user?.organisations, organisationSlug])

  React.useEffect(() => {
    if (user?.id && organisationSlug) {
      return subscribeToMemberData(user.id, organisationSlug, md => {
        updateUser(u => ({ ...u, orgData: { ...u?.orgData, [organisationSlug]: md } }))
      })
    }
  }, [user?.id, organisationSlug])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMainLoading(false)
    }, 1500)
    return () => clearTimeout(timer)
  }, [])

  React.useEffect(() => {
    setUserOrgStatus(null)
    if (organisationSlug && user?.id) {
      fetchMember(organisationSlug, user.id).then(m => {
        if (m?.status) {
          setUserOrgStatus(m.status)
        }
      })
    }
  }, [user?.id, organisationSlug])

  return null
}
