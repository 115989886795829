import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore, collection, doc, setDoc } from "firebase/firestore"

const leadsRef = () => collection(getFirestore(app), "leads")

export const sendSuggestion = (
  name: string,
  email: string,
  suggestions: string,
  suggestion_type: string,
  actionSuccess?: () => void,
  actionError?: (errorMessage: string) => void,
) => {
  if (suggestions && suggestions.length > 2) {
    setDoc(doc(leadsRef(), String(Date.now())), {
      name,
      email,
      message: `New ${suggestion_type}: ${suggestions}`,
      tags: [suggestion_type],
    })
      .then(actionSuccess)
      .catch(error => {
        actionError ? actionError(error.message) : console.log(error)
      })
  }
}
