import * as React from "react"
import { DateTime, Duration } from "luxon"
import Tippy from "@tippyjs/react"
import { useSwipeable } from "react-swipeable"
import { CalendarObject } from "../../business_logic/model"

const getMonday = (date: number) => {
  const datetime = DateTime.fromMillis(date)
  return datetime.minus(Duration.fromObject({ days: datetime.weekday - 1 })).startOf("day")
}
const getDays = () => {
  const monday = getMonday(Date.now())
  return Array(7)
    .fill(0)
    .map((x, i) => monday.plus(Duration.fromObject({ days: i })).weekdayShort)
}
const hours = Array(48)
  .fill(0)
  .map((x, i) => `${String(~~(i / 2)).padStart(2, "0")}:${String((i % 2) * 30).padStart(2, "0")}`)

interface CalendarWeekProps {
  maxHeight?: number
  date?: number
  hideDate?: boolean
  onPreviousWeek?: () => void
  onNextWeek?: () => void
  calendarObjects?: CalendarObject[]
  onDateClick?: (date: string, day: number, startTime: string) => void
  deleteEvent?: (event: CalendarObject) => void
  minDate?: number
  maxDate?: number
}

export const CalendarWeek = ({
  maxHeight,
  date,
  hideDate,
  onPreviousWeek,
  onNextWeek,
  calendarObjects,
  onDateClick,
  deleteEvent,
  minDate,
  maxDate,
}: CalendarWeekProps) => {
  const days = getDays()
  const monday = getMonday(date ? date : Date.now())
  const datetimes = hideDate
    ? days
    : days.map((d, i) => monday.plus(Duration.fromObject({ days: i })))

  const noPrevious =
    minDate &&
    monday.minus(Duration.fromObject({ days: 1 })).toMillis() <
      DateTime.fromMillis(minDate).startOf("day").toMillis()
  const noNext =
    maxDate &&
    monday.plus(Duration.fromObject({ days: 7 })).toMillis() >
      DateTime.fromMillis(maxDate).startOf("day").toMillis()

  const wrapperRef = React.useRef<HTMLDivElement>()
  const swipeHandlers = useSwipeable({
    onSwipedLeft: !noNext && onNextWeek,
    onSwipedRight: !noPrevious && onPreviousWeek,
    onSwiping: ({ absX, dir, event, first }) => {
      if (first && ["Left", "Right"].includes(dir)) {
        event.stopPropagation()
        event.preventDefault()
      }
      if (dir === "Left" && wrapperRef.current && !noNext) {
        const headers = wrapperRef.current.children[0] as HTMLDivElement
        const days = wrapperRef.current.children[1] as HTMLDivElement
        Array.from(days.children)
          .slice(1)
          .forEach((day: HTMLDivElement) => {
            day.style.transform = `translateX(-${Math.min(50, absX)}px)`
          })
        Array.from(headers.children)
          .slice(1)
          .forEach((day: HTMLDivElement) => {
            day.style.transform = `translateX(-${Math.min(50, absX)}px)`
          })
      }
      if (dir === "Right" && wrapperRef.current && !noPrevious) {
        const headers = wrapperRef.current.children[0] as HTMLDivElement
        const days = wrapperRef.current.children[1] as HTMLDivElement
        Array.from(days.children)
          .slice(1)
          .forEach((day: HTMLDivElement) => {
            day.style.transform = `translateX(${Math.min(50, absX)}px)`
          })
        Array.from(headers.children)
          .slice(1)
          .forEach((day: HTMLDivElement) => {
            day.style.transform = `translateX(${Math.min(50, absX)}px)`
          })
      }
    },
    onSwiped: () => {
      if (wrapperRef.current) {
        const headers = wrapperRef.current.children[0] as HTMLDivElement
        const days = wrapperRef.current.children[1] as HTMLDivElement
        Array.from(days.children)
          .slice(1)
          .forEach((day: HTMLDivElement) => {
            day.style.transform = `translateX(0)`
          })
        Array.from(headers.children)
          .slice(1)
          .forEach((day: HTMLDivElement) => {
            day.style.transform = `translateX(0)`
          })
      }
    },
    delta: 50,
    trackMouse: false,
    trackTouch: true,
    preventDefaultTouchmoveEvent: true,
  })

  React.useEffect(() => {
    if (wrapperRef.current) {
      setTimeout(() => {
        wrapperRef.current.scroll({ top: 360, behavior: "smooth" })
      }, 150)
    }
  }, [])

  return (
    <div
      className="w-full h-full flex flex-col overflow-auto thin-scrollbar rounded bg-white dark:bg-primary-850 relative"
      style={{ maxHeight }}
      ref={wrapperRef}
    >
      <div className="w-full flex sticky top-0 z-30">
        <div className="flex flex-col flex-0 flex-shrink-0 w-14 px-0.5 py-1 text-center border-b border-zinc-200 dark:border-zinc-700 bg-zinc-300 dark:bg-zinc-700 sticky left-0 z-10">
          {hideDate ? (
            ""
          ) : (
            <>
              <span>{monday.monthShort}</span>
              <span>{monday.year}</span>
            </>
          )}
        </div>
        {datetimes.map((datetime, i) => (
          <CalendarDayHeader
            datetime={datetime}
            hideDate={hideDate}
            onPreviousWeek={onPreviousWeek}
            onNextWeek={onNextWeek}
            calendarObjects={calendarObjects}
            minDate={minDate}
            maxDate={maxDate}
            key={`dayHeader-${i}`}
          />
        ))}
      </div>
      <div className="w-full flex flex-1 relative" {...swipeHandlers}>
        <div className="flex-0 w-14 flex-shrink-0 flex flex-col pt-1 sticky left-0 bg-zinc-100 dark:bg-zinc-800 z-10">
          {hours.map((hour, j) => (
            <div
              className="flex-1 h-5 max-h-[1.25rem] text-xs px-1.5 text-right font-mono"
              key={`${hour}-label`}
            >
              {j % 2 === 0 ? hour : ""}
            </div>
          ))}
        </div>
        {datetimes.map((datetime, i) => (
          <CalendarDayBody
            datetime={datetime}
            calendarObjects={calendarObjects}
            onDateClick={onDateClick}
            deleteEvent={deleteEvent}
            minDate={minDate}
            maxDate={maxDate}
            key={`dayBody-${i}`}
          />
        ))}
      </div>
    </div>
  )
}

const CalendarDayHeader = ({
  datetime,
  hideDate,
  onPreviousWeek,
  onNextWeek,
  calendarObjects,
  minDate,
  maxDate,
}) => {
  const days = getDays()
  const day = typeof datetime === "number" ? datetime : datetime.weekday
  const dayNumber = typeof datetime === "number" ? null : datetime.day
  const dateStr = typeof datetime === "number" ? String(datetime) : datetime.toFormat("yyyy-MM-dd")
  const dayEvents = calendarObjects.filter(
    e => e.type == "event" && e.date === dateStr && (e.deletable || e.modifiable),
  )
  const previousWeeksEvents =
    day === 1 &&
    calendarObjects.filter(
      e => e.type == "event" && e.date < dateStr && (e.deletable || e.modifiable),
    )
  const nextWeeksEvents =
    day === 7 &&
    calendarObjects.filter(
      e => e.type == "event" && e.date > dateStr && (e.deletable || e.modifiable),
    )
  const eventIndicator = (
    <span
      className={`w-2 h-2 absolute bg-secondary-500 dark:bg-secondary-400 rounded-full z-20 top-0 -right-2.5`}
    />
  )
  const dayEventIndicator = dayEvents.length > 0 && eventIndicator
  const noPrevious =
    day === 1 &&
    minDate &&
    datetime.minus(Duration.fromObject({ days: 1 })).toMillis() <
      DateTime.fromMillis(minDate).startOf("day").toMillis()
  const noNext =
    day === 7 &&
    maxDate &&
    datetime.plus(Duration.fromObject({ days: 1 })).toMillis() >
      DateTime.fromMillis(maxDate).startOf("day").toMillis()

  return (
    <div
      className={`relative flex flex-col items-center flex-1 min-w-[2.5rem] md:min-w-[5rem] px-0.5 py-1 text-center border-b border-zinc-200 dark:border-zinc-700 bg-zinc-200 dark:bg-primary-850 flex-0 flex-shrink-0 ${
        day === 1 && !hideDate ? "pl-1.5 sm:pl-0" : ""
      } ${day === 7 && !hideDate ? "pr-1.5 sm:pr-0" : ""} transition-all duration-100`}
    >
      <span className="hidden md:block relative">
        {days[day - 1]}
        {dayEventIndicator}
      </span>
      <span className="block md:hidden text-sm relative">
        {days[day - 1][0]}
        {dayEventIndicator}
      </span>
      {dayNumber}
      {day === 1 && !hideDate && !noPrevious && (
        <button
          className={`absolute left-0 top-0 h-full transition duration-75 flex items-center ${
            previousWeeksEvents.length > 0
              ? "text-secondary-500 hover:text-secondary-600 dark:hover:text-secondary-400"
              : "text-zinc-500 hover:text-black dark:hover:text-white"
          }`}
          onClick={e => {
            e.preventDefault()
            onPreviousWeek && onPreviousWeek()
          }}
          disabled={noPrevious}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 sm:h-6 sm:w-6 md:h-7 md:w-7"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke="none"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
      )}
      {day === 7 && !hideDate && !noNext && (
        <button
          className={`absolute right-0 top-0 h-full transition duration-75 flex items-center ${
            nextWeeksEvents.length > 0
              ? "text-secondary-500 hover:text-secondary-600 dark:hover:text-secondary-400"
              : "text-zinc-500 hover:text-black dark:hover:text-white"
          }`}
          onClick={e => {
            e.preventDefault()
            onNextWeek && onNextWeek()
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 sm:h-6 sm:w-6 md:h-7 md:w-7"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke="none"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      )}
    </div>
  )
}

interface CalendarDayBodyProps {
  datetime: any
  calendarObjects: CalendarObject[]
  onDateClick?: (date: string, day: number, startTime: string) => void
  deleteEvent?: (event: CalendarObject) => void
  minDate?: number
  maxDate?: number
}

const CalendarDayBody = ({
  datetime,
  calendarObjects,
  onDateClick,
  deleteEvent,
  minDate,
  maxDate,
}: CalendarDayBodyProps) => {
  const day: number = typeof datetime === "number" ? datetime : datetime.weekday
  const dateStr: string =
    typeof datetime === "number" ? String(datetime) : datetime.toFormat("yyyy-MM-dd")
  const dayObjects = calendarObjects.filter(e => e.date === dateStr || e.day === day)

  return (
    <div
      className={`flex-1 min-w-[2.5rem] md:min-w-[5rem] flex flex-col pt-3 relative ${
        day % 2 === 0 ? "bg-zinc-300/20 dark:bg-primary-750/20" : ""
      } transition-all duration-100`}
      key={`${day}-col`}
    >
      {hours.map((hour, j) => {
        let disabled = false
        if (typeof datetime !== "string") {
          const timeMillis = DateTime.fromFormat(
            `${dateStr} ${hour}`,
            "yyyy-MM-dd HH:mm",
          ).toMillis()
          disabled = timeMillis < minDate || timeMillis > maxDate
        }
        return (
          <button
            className={`flex-1 h-5 max-h-[1.25rem] min-h-[1.25rem] border-b first:border-t ${
              j % 2 === 1
                ? "border-zinc-400 dark:border-zinc-500"
                : "border-zinc-200 dark:border-zinc-700"
            } first:border-t-zinc-400 dark:first:border-t-zinc-500 ${
              disabled
                ? "bg-red-100/50 dark:bg-red-700/25"
                : "hover:bg-emerald-300/20 dark:hover:bg-emerald-300/20"
            }`}
            key={`${day}-${j}-btn`}
            tabIndex={-1}
            onClick={e => {
              e.preventDefault()
              onDateClick && onDateClick(dateStr, day, hour)
            }}
            disabled={disabled}
          />
        )
      })}
      {dayObjects.map((object, i) => {
        if (object.type === "event")
          return <CalendarEvent key={`event-${i}`} event={object} deleteEvent={deleteEvent} />
        if (object.type === "marker") return <CalendarMarker key={`marker-${i}`} marker={object} />
      })}
    </div>
  )
}

interface CalendarEventProps {
  event: CalendarObject
  deleteEvent?: (event: CalendarObject) => void
}

const CalendarEvent = ({ event, deleteEvent }: CalendarEventProps) => {
  const startPos = hours.indexOf(event.startTime) * 1.25 + 0.75 // rem
  const height = event.duration * 2.5 + 1 / 16 // rem
  let bgColor = "bg-secondary-500/25 hover:bg-secondary-500/30"
  let borderColor = "border-secondary-500"
  if (event.color === "red") {
    bgColor = "bg-red-500/25 hover:bg-red-500/30"
    borderColor = "border-red-500"
  }
  if (event.color === "gray") {
    bgColor = "bg-zinc-300 dark:bg-zinc-600"
    borderColor = "border-zinc-300 dark:border-zinc-600"
  }
  return (
    <Tippy
      content={
        event.description && (
          <div
            className="relative px-2 py-1 bg-primary-900 shadow text-zinc-100 text-sm rounded-sm flex justify-center whitespace-pre-wrap text-center"
            role="tooltip"
          >
            {event.description}
            <div className="absolute border-x-8 border-t-8 border-x-transparent border-t-primary-900 -bottom-2" />
          </div>
        )
      }
    >
      <div
        className={`absolute -mt-px w-full left-0 ${bgColor} border border-l-8 ${borderColor} h-10 rounded-sm transition duration-75 z-20`}
        style={{ top: `${startPos}rem`, height: `${height}rem` }}
        onClick={e => {
          e.stopPropagation()
          event.deletable && deleteEvent(event)
        }}
      >
        {event.modifiable && (
          <>
            <div
              className={`absolute bg-zinc-100 dark:bg-primary-850 border-2 ${borderColor} w-3 h-3 -top-2 right-1.5 rounded-full cursor-ns-resize`}
            />
            <div
              className={`absolute bg-zinc-100 dark:bg-primary-850 border-2 ${borderColor} w-3 h-3 -bottom-2 left-1.5 rounded-full cursor-ns-resize`}
            />
          </>
        )}
      </div>
    </Tippy>
  )
}

interface CalendarMarkerProps {
  marker: CalendarObject
}

const CalendarMarker = ({ marker }: CalendarMarkerProps) => {
  const startPos = hours.indexOf(marker.startTime) * 1.25 + 0.75 // rem
  const height = marker.duration * 2.5 // rem
  return (
    <Tippy
      content={
        marker.description && (
          <div
            className="relative px-2 py-1 bg-primary-900 shadow text-zinc-100 text-sm rounded flex justify-center whitespace-pre-wrap text-center"
            role="tooltip"
          >
            {marker.description}
            <div className="absolute border-x-8 border-t-8 border-x-transparent border-t-primary-900 -bottom-2" />
          </div>
        )
      }
    >
      <div
        className="absolute w-1 sm:w-2 left-0 bg-emerald-300 dark:bg-emerald-500 transition-all duration-200 z-10"
        style={{ top: `${startPos}rem`, height: `${height}rem` }}
      />
    </Tippy>
  )
}

interface CalendarProps {
  maxHeight?: number
  dateInit?: number
  hideDate?: boolean
  calendarObjects?: CalendarObject[]
  onDateClick?: (date: string, day: number, startTime: string) => void
  deleteEvent?: (event: CalendarObject) => void
  minDate?: number
  maxDate?: number
  updateDate?: (date: number) => void
}

export const Calendar = ({
  maxHeight,
  dateInit,
  hideDate,
  calendarObjects,
  onDateClick,
  deleteEvent,
  minDate,
  maxDate,
  updateDate,
}: CalendarProps) => {
  const [date, setDate] = React.useState(getMonday(dateInit ? dateInit : Date.now()).toMillis())

  const addSevenDays = () => {
    setDate(d =>
      DateTime.fromMillis(d)
        .plus(Duration.fromObject({ days: 7 }))
        .toMillis(),
    )
  }
  const removeSevenDays = () => {
    setDate(d =>
      DateTime.fromMillis(d)
        .minus(Duration.fromObject({ days: 7 }))
        .toMillis(),
    )
  }

  React.useEffect(() => {
    updateDate && updateDate(date)
  }, [updateDate, date])

  return (
    <CalendarWeek
      maxHeight={maxHeight}
      date={date}
      hideDate={hideDate}
      onNextWeek={addSevenDays}
      onPreviousWeek={removeSevenDays}
      calendarObjects={calendarObjects}
      onDateClick={onDateClick}
      deleteEvent={deleteEvent}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}
